import React, { FC } from 'react';
import { Route, getLinks } from 'react-router-hoc';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Switch, Redirect } from 'react-router-dom';
import { usePermissions, useTrackScreenView } from 'hooks';
import { ActionsType } from 'generated/types';

import { GetRouteProps, ModuleName, ScreenName } from 'types';
import { EmptyState, TabItem } from 'components';
import { Seniority } from './Seniority';
import { Specialization } from './Specialization';
import { CompensationTypes } from './CompensationTypes';

import styles from '../Settings.module.scss';

export const links = getLinks({
  Specialization,
  Seniority,
  CompensationTypes,
});

const TeamSettingsRoute = Route('/settings/team-settings');

type Props = GetRouteProps<typeof TeamSettingsRoute>;

const TeamSettings: FC<Props> = () => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  useTrackScreenView(ModuleName.settings, ScreenName.teamSettings);

  if (!hasAccess(ActionsType.OtherSettings)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <div className="flex max-h-100 align-start">
        <div className={clsx(styles.tabs, 'flex-column align-start mr-30')}>
          <TabItem exact={false} route={links.Specialization()} activeClassName={styles.active}>
            {t('settings.teamSettings.specializations')}
          </TabItem>
          <TabItem exact={false} route={links.Seniority()} activeClassName={styles.active}>
            {t('settings.teamSettings.seniorities')}
          </TabItem>
          <TabItem exact={false} route={links.CompensationTypes()} activeClassName={styles.active}>
            {t('settings.teamSettings.compensationTypes')}
          </TabItem>
        </div>

        <div className="flex-1 max-h-100 flex flex-column">
          <Switch>
            <Seniority />
            <Specialization />
            <CompensationTypes />
            <Redirect to={links.Specialization()} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default TeamSettingsRoute(TeamSettings);
