import { idRegEx } from 'utils/regex';
import { Route } from 'react-router-hoc';
import { ModalModeEnum } from 'types';
import CompensationTypesComponent from './CompensationTypes';

export const CompensationTypesRoute = Route(
  {
    mode: Route.params.oneOf(ModalModeEnum.create, ModalModeEnum.edit).optional,
    compensationId: Route.params.regex(idRegEx).optional,
  },
  ({ mode, compensationId }) => `/settings/team-settings/compensation-types/${mode}/${compensationId}`,
);

export const CompensationTypes = CompensationTypesRoute(CompensationTypesComponent);
