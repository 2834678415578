import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import {
  AbsoluteSpinner,
  AssignmentForm,
  AssignmentFormValues,
  BillingTypes,
  ConfirmModal,
  EmptyState,
} from 'components';
import {
  BillableLeaveRuleBalanceDocument,
  MemberAssignmentsDocument,
  MemberProjectsDistributionDocument,
  useAssignmentQuery,
  useDeleteAssignmentMutation,
  useEditAssignmentMutation,
} from 'generated/graphql';
import { getAssignmentFormattedFormData, graphqlOnError } from 'utils';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, useIsOpen, usePermissions } from 'hooks';
import { ActionsType, Project } from 'generated/types';
import { removeUTCTimezone } from 'utils/date';

interface NewAssignmentProps {
  id: string;
  memberId?: string;
  projectId?: string;
  onCancel: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  memberDisabled?: boolean;
  projectDisabled?: boolean;
}

const INITIAL_ALLOCATION_VALUE = 8.0;

export const EditAssignment: FC<NewAssignmentProps> = ({
  id,
  memberId,
  projectId,
  onCancel,
  onDelete,
  onUpdate,
  memberDisabled,
  projectDisabled,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [editedAssignment, setEditedAssignment] = useState<AssignmentFormValues | null>(null);
  const [isOpenEditConfirm, onOpenEditConfirm, onCloseEditConfirm] = useIsOpen(false);
  const [isOpenDeleteConfirm, onOpenDeleteConfirm, onCloseDeleteConfirm] = useIsOpen(false);
  const { hasAccess, isPermissionsLoading } = usePermissions();
  const { data, loading: assignmentLoading } = useAssignmentQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company?.id,
      assignmentId: id!,
    },
    skip: !userData?.company?.id || !id,
  });

  const initialValue = useMemo<AssignmentFormValues>(
    () => ({
      member: data?.assignment?.member || null,
      project: (data?.assignment?.project as Project) || null,
      role: data?.assignment?.role || null,
      seniority:
        data?.assignment?.seniority && data?.assignment!.seniorityId
          ? { name: data?.assignment!.seniority, id: data?.assignment!.seniorityId }
          : null,
      startDate: data?.assignment?.startDate ? removeUTCTimezone(data.assignment.startDate) : new Date(),
      endDate: data?.assignment?.endDate ? removeUTCTimezone(data.assignment.endDate) : new Date(),
      billingType:
        BillingTypes.find((type) => type.id === data?.assignment?.bill_amount_calculation_type) || BillingTypes[0],
      allocationTimeAmount: data?.assignment?.allocationTimeAmount
        ? data.assignment.allocationTimeAmount
        : INITIAL_ALLOCATION_VALUE,
      billable: data?.assignment?.billable ?? false,
    }),
    [data],
  );

  const [editAssignment, { loading }] = useEditAssignmentMutation({
    onCompleted() {
      toast.success(t('forms.newAssignment.editedSuccessfully'));
      onCancel();
      onUpdate?.();
      mixpanel.track('Assignment edited', { 'Drag and drop': false, Bulk: false });
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [BillableLeaveRuleBalanceDocument, MemberProjectsDistributionDocument, MemberAssignmentsDocument],
  });

  const [deleteAssignment] = useDeleteAssignmentMutation({
    onCompleted() {
      toast.success(t('forms.newAssignment.deletedSuccessfully'));
      onDelete?.();
      onCancel();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
  });

  const onEditAssignment = (formValues: AssignmentFormValues) => {
    const data = {
      variables: {
        data: getAssignmentFormattedFormData(formValues),
        assignmentId: id,
        companyId: userData!.company.id,
      },
    };

    editAssignment(data);
  };

  const handleDelete = async () => {
    if (id && userData?.company.id) {
      await deleteAssignment({
        variables: {
          assignmentsIds: [id],
          companyId: userData.company.id,
        },
      });
    }
  };

  const handleSubmit = useCallback((values: AssignmentFormValues) => {
    setEditedAssignment(values);
    onOpenEditConfirm();
  }, []);

  if (isPermissionsLoading || assignmentLoading) {
    return <AbsoluteSpinner />;
  }

  if (!hasAccess(ActionsType.EditAssignments)) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <>
      <AssignmentForm
        memberId={memberId}
        projectId={projectId}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        initialValue={initialValue}
        memberDisabled={memberDisabled}
        projectDisabled={projectDisabled}
        submitLabel={t('actions.save')}
        submitDisabled={loading}
        additionalButton={
          <Button variant="outlined" className="danger-btn" onClick={onOpenDeleteConfirm}>
            {t('forms.delete')}
          </Button>
        }
      />

      <ConfirmModal
        title={t('forms.newAssignment.deleteAssignment')}
        submitButtonTitle={t('forms.newAssignment.deleteAssignment')}
        isDelete
        isOpen={isOpenDeleteConfirm}
        onSubmit={() => {
          handleDelete();
          onCloseDeleteConfirm();
        }}
        onClose={onCloseDeleteConfirm}
      >
        {t('forms.newAssignment.confirmDelete')}
      </ConfirmModal>

      <ConfirmModal
        title={t('forms.newAssignment.editAssignment')}
        isOpen={isOpenEditConfirm}
        onSubmit={() => {
          editedAssignment && onEditAssignment(editedAssignment);
          onCloseEditConfirm();
        }}
        onClose={() => {
          onCloseEditConfirm();
        }}
      >
        {t('forms.newAssignment.confirmEdit')}
      </ConfirmModal>
    </>
  );
};
