import React, { useMemo } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { LoadingButton } from 'components';
import { submitForm } from 'utils';

export interface NewFileFolderFormValues {
  name: string;
}

interface NewClientProps {
  onSubmit: (values: NewFileFolderFormValues) => void | Promise<void>;
  name?: string;
  folderId?: string;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
}

export const NewFileFolder = ({ name, submitLabel, cancelLabel, onSubmit, onCancel }: NewClientProps) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(t('validation.required'))
          .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
      }),
    [],
  );

  const handleSubmit = async (
    values: NewFileFolderFormValues,
    { setSubmitting }: FormikHelpers<NewFileFolderFormValues>,
  ) => {
    await submitForm(values, setSubmitting, onSubmit);
    onCancel();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: name ?? '',
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="form">
          <div>
            <InputLabel className="required">{t('forms.newFileFolder.folderName')}</InputLabel>
            <Field
              component={TextField}
              name="name"
              placeholder={t('forms.newFileFolder.folderName')}
              className="mb-24"
            />
          </div>
          <div className="controls">
            <LoadingButton type="submit" loading={isSubmitting} className="mr-8">
              {submitLabel ?? t('forms.create')}
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel ?? t('forms.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
