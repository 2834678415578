import React, { FC, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { useProjectManagersQuery } from 'generated/graphql';
import { graphqlOnError, sortByField } from 'utils';
import { EmptyState, LoadingButton, MemberTag } from 'components';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder, usePermissions } from 'hooks';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ActionsType, CompanyUserStatus, Member, MemberType } from 'generated/types';
import { ASC, FIRST_NAME, LAST_NAME } from 'consts';

type User = Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>;
interface Props {
  children: ReactNode;
  loading?: boolean;
  value: User | null;
  onChange: (value: User) => void;
}

const SELECT_TAG_COLOR = '#f5f6f7';

export const UsersTagMenu: FC<Props> = ({ children, loading, value, onChange }) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { data: { users = [] } = {}, loading: usersLoading } = useProjectManagersQuery({
    skip: !hasAccess(ActionsType.ViewUsers) || !anchorEl,
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const membersOptions = useMemo(() => {
    const members = users
      .filter(({ status, type }) => status !== CompanyUserStatus.Deactivated && type !== MemberType.Employee)
      .map(({ member }) => member);
    return sortByField(members, ASC, FIRST_NAME, LAST_NAME);
  }, [usersLoading]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleOpen = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleClick(e);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const isFewProjectManagersOptions = membersOptions.length > 1;

  return (
    <div>
      <LoadingButton
        size="medium"
        color="secondary"
        loading={loading}
        onClick={handleOpen}
        className={styles.userCellButton}
      >
        {children}
      </LoadingButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl) && !(usersLoading && !membersOptions?.length)}
        onClose={handleClose}
      >
        {isFewProjectManagersOptions ? (
          <span className={styles.title}>{t('projects.assignTo')}</span>
        ) : (
          <EmptyState className={styles.emptyMessage} title={t('projects.noUsersFound')} />
        )}
        {membersOptions.map((member) => {
          const isSelected = member.id === value?.id;

          return (
            <MenuItem
              key={member.id}
              onClick={(e) => {
                !isSelected && onChange(member);
                handleClose(e);
              }}
              className={clsx('flex justify-content-between py-4', isSelected && 'd-none')}
            >
              <MemberTag
                key={member.id}
                firstName={member.first_name!}
                lastName={member.last_name!}
                avatarColor={member.color}
                color={isSelected ? SELECT_TAG_COLOR : ''}
                className={clsx(styles.tag)}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
