import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useExternalRatesQuery } from 'generated/graphql';
import { formatAmount, getCurrencySymbol, graphqlOnError, valueToCurrency } from 'utils';
import { useAuth, useSettings } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { Warning } from 'icons';
import { addDays, areIntervalsOverlapping, format, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { DD_MMM_YYYY } from 'consts';
import { ExternalRateDataFragment } from 'generated/types';

import styles from './styles.module.scss';

interface Props {
  projectId: string;
  roleId: string;
  seniorityId?: string;
  currency: string;
  startDate?: string | Date;
  endDate?: string | Date;
}

interface EmptyRate {
  start_date: Date;
  end_date: Date;
}

const getPeriodLabel = (startDate?: string | Date, endDate?: string | Date): string => {
  const start = startDate ? new Date(startDate) : new Date();
  const end = endDate ? new Date(endDate) : null;

  if (!end || isSameDay(start, end)) {
    return `${format(start, DD_MMM_YYYY)}`;
  }

  return `${format(start, DD_MMM_YYYY)} - ${format(end!, DD_MMM_YYYY)}`;
};

export const AssignmentRate: FC<Props> = ({ projectId, roleId, seniorityId, currency, startDate, endDate }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { isFinancialsHidden } = useSettings();

  const { data: { externalRates = [] } = {}, loading: ratesLoading } = useExternalRatesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId: projectId,
      isPast: true,
    },
  });

  const getIsRateInRange = (rateStart: string, rateEnd: string) => {
    const checkNoFilters = !startDate && !endDate;

    const checkFilterOnlyByAssignmentEndDate =
      !startDate && endDate && isBefore(new Date(rateStart), new Date(endDate));

    const checkFilterOnlyByAssignmentStartDate =
      !endDate && startDate && (!rateEnd || isAfter(new Date(rateEnd), new Date(startDate)));

    const checkFilterRateWithoutEndDateInAssignmentRange = endDate
      ? !rateEnd &&
        startDate &&
        endDate &&
        isAfter(new Date(rateStart), new Date(startDate)) &&
        isBefore(new Date(rateStart), new Date(endDate))
      : !rateEnd && startDate && endDate && isAfter(new Date(rateStart), new Date(startDate));

    const checkFilterRateWithoutEndDateAfterAssignmentStart =
      !rateEnd && startDate && endDate && isBefore(new Date(rateStart), new Date(endDate));

    const checkFilterAssignmentIntervalOverlapRate =
      startDate &&
      endDate &&
      rateEnd &&
      areIntervalsOverlapping(
        { start: new Date(startDate), end: new Date(endDate) },
        {
          start: new Date(rateStart),
          end: new Date(rateEnd),
        },
      );

    return (
      checkNoFilters ||
      checkFilterOnlyByAssignmentEndDate ||
      checkFilterOnlyByAssignmentStartDate ||
      checkFilterRateWithoutEndDateInAssignmentRange ||
      checkFilterRateWithoutEndDateAfterAssignmentStart ||
      checkFilterAssignmentIntervalOverlapRate
    );
  };

  const rates: ExternalRateDataFragment[] = externalRates
    .filter((rate) => {
      return seniorityId
        ? rate.roleId === roleId && rate.seniorityId === seniorityId && getIsRateInRange(rate.start_date, rate.end_date)
        : rate.roleId === roleId && !rate.seniorityId && getIsRateInRange(rate.start_date, rate.end_date);
    })
    .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

  const ratesPeriods = rates.reduce<(ExternalRateDataFragment | EmptyRate)[]>((acc, rate, currentIndex) => {
    let result: (ExternalRateDataFragment | EmptyRate)[] = [];
    const isFirstRate = !currentIndex;
    if (isFirstRate) {
      const rateStart = rate.start_date;
      const isNoRatesFromStart =
        !startDate || !rateStart
          ? false
          : !isSameDay(new Date(rateStart), new Date(startDate)) && isAfter(new Date(rateStart), new Date(startDate));
      const emptyFirstRate = {
        start_date: startDate ? new Date(startDate) : new Date(),
        end_date: subDays(new Date(rateStart), 1),
      };
      result = isNoRatesFromStart ? [emptyFirstRate] : [];
    }

    const prevRate = acc[acc.length - 1];
    const isEmptyBetweenRates = !prevRate
      ? false
      : !isSameDay(addDays(new Date(prevRate.end_date), 1), new Date(rate.start_date));
    const emptyRate = {
      start_date: addDays(new Date(prevRate?.end_date), 1),
      end_date: subDays(new Date(rate.start_date), 1),
    };
    result = isEmptyBetweenRates ? [...result, emptyRate, rate] : [...result, rate];

    const isLastRate = currentIndex === rates.length - 1;
    if (isLastRate) {
      const rateEnd = rate.end_date;
      const isNoRatesToEnd =
        !endDate || !rateEnd
          ? false
          : !isSameDay(new Date(endDate), new Date(rateEnd)) && isAfter(new Date(endDate), new Date(rateEnd));

      const emptyLastRate = {
        start_date: addDays(new Date(rate.end_date), 1),
        end_date: endDate ? new Date(endDate) : new Date(),
      };
      result = isNoRatesToEnd ? [...result, emptyLastRate] : [...result];
    }

    return [...acc, ...result];
  }, []);

  if (ratesLoading) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      <span>{t('rateCard.roleRates')}</span>
      {ratesPeriods.reverse().map((rate) => {
        const startDateValue =
          startDate && isAfter(new Date(startDate), new Date(rate.start_date)) ? startDate : rate.start_date;
        const endDateValue =
          endDate && (!rate.end_date || isAfter(new Date(rate.end_date), new Date(endDate))) ? endDate : rate.end_date;

        return (
          <div key={rate.start_date} className="flex flex-column gap-4">
            {'rate_amount' in rate ? (
              <span className={styles.rateLabel}>
                {getCurrencySymbol(currency)}
                {formatAmount(valueToCurrency(rate.rate_amount), isFinancialsHidden)} / {rate.unit}.
              </span>
            ) : (
              <span className={styles.rateLabel}>
                <Warning className="mr-4" />
                {t('rateCard.noRate')}
              </span>
            )}
            <span className={styles.periodLabel}>
              {endDateValue
                ? getPeriodLabel(startDateValue, endDateValue)
                : `${getPeriodLabel(startDateValue)} - ${t('rateCard.noEndDate')}`}
            </span>
          </div>
        );
      })}
      {!rates.length && (
        <span className={styles.periodLabel}>
          <Warning className="mr-4" />
          {t('rateCard.noRates')}.
        </span>
      )}
    </div>
  );
};
