import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, DialogWrapper, LoadingButton, Portal } from 'components';
import { useExportProjectsReportLazyQuery } from 'generated/graphql';
import { downloadExcelXLS, graphqlOnError, isAdminPermission } from 'utils';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import { useAuth } from 'contexts';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { addYears, endOfMonth, format, isSameMonth, isSameYear, startOfMonth } from 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import * as Yup from 'yup';
import { DEFAULT_DATE_FORMAT } from 'consts';

import styles from './styles.module.scss';

interface FormValues {
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export const ExportProjectsAdminReportModal: FC = () => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isOpen, onOpen, onClose] = useIsOpen();

  const { handleSubmit, setValues, setFieldTouched, submitCount, values, errors } = useFormik<FormValues>({
    initialValues: { startDate: new Date(), endDate: new Date() },
    validationSchema: Yup.object({
      startDate: Yup.date().nullable().required(t('insights.utilization.adminReport.reportPeriodRequired')),
      endDate: Yup.date()
        .nullable()
        .required(t('insights.utilization.adminReport.reportPeriodRequired'))
        .when('startDate', (val: string, schema: any) => {
          if (val) {
            const startDate = new Date(val);
            return (
              val && schema.max(addYears(startDate, 1), t('insights.utilization.adminReport.periodNoMoreThatYearError'))
            );
          }
        }),
    }),
    onSubmit: (data) => {
      exportProjectsReport({
        variables: {
          companyId: userData!.company.id,
          data: {
            start: format(startOfMonth(new Date(data.startDate!)), DEFAULT_DATE_FORMAT),
            end: format(endOfMonth(new Date(data.endDate!)), DEFAULT_DATE_FORMAT),
          },
        },
      });
      onClose();
    },
  });

  const getReportTitle = ({ startDate, endDate }: { startDate: string | Date; endDate: string | Date }): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const isSameYearPeriod = isSameYear(start, end);
    const isSameMonthPeriod = isSameMonth(start, end);
    const period = `${format(start, isSameYearPeriod ? 'MMMM' : 'MMMM, yyyy')}${isSameMonthPeriod ? '' : '–'}${format(
      end,
      isSameMonthPeriod ? ', yyyy' : 'MMMM, yyyy',
    )}`;
    const currentDate = format(new Date(), 'dd-MM-yyyy');

    return `${t('insights.utilization.adminReport.projectsReport')} - ${period} ${t(
      'insights.utilization.adminReport.asOf',
    )} ${currentDate}`;
  };

  const [exportProjectsReport, { loading: exportLoading }] = useExportProjectsReportLazyQuery({
    onCompleted(data) {
      if (data?.projectsAdminReport) {
        downloadExcelXLS(
          data.projectsAdminReport,
          getReportTitle({
            startDate: values.startDate || '',
            endDate: values.endDate || '',
          }),
        );
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  // TODO: update isAdminPermission
  return (
    <>
      {userData && isAdminPermission(userData.type) ? (
        <Button variant="outlined" color="secondary" onClick={onOpen}>
          {t('insights.utilization.adminReport.exportProjectsReport')}
        </Button>
      ) : (
        ''
      )}

      <DialogWrapper
        open={isOpen}
        onClose={onClose}
        className={styles.dialog}
        contentClassName={styles.dialogContent}
        title={t('insights.utilization.adminReport.exportProjectsReport')}
      >
        <form className="px-24">
          <div className="flex">
            <div className="flex-1">
              <InputLabel required>{t('insights.utilization.adminReport.reportPeriod')}</InputLabel>
              <DatePicker
                showMonthYearPicker
                range
                placeholder={t('insights.utilization.adminReport.selectPeriod')}
                name="startDate"
                dateFormat="MMM yyyy"
                value={[values.startDate, values.endDate]}
                error={Boolean(submitCount && (errors.startDate || errors.endDate))}
                helperText={!!submitCount && (errors.startDate || errors.endDate)}
                onClick={() => setFieldTouched('startDate')}
                onChange={(dates) =>
                  setValues({
                    ...values,
                    startDate: dates?.[0] as FormValues['startDate'],
                    endDate: dates?.[1] as FormValues['endDate'],
                  })
                }
              />
            </div>
          </div>
        </form>
        <Portal wrapperId="dialog-actions">
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('forms.cancel')}
          </Button>
          <LoadingButton loading={exportLoading} onClick={() => handleSubmit()}>
            {t('insights.utilization.adminReport.exportProjectsReport')}
          </LoadingButton>
        </Portal>
      </DialogWrapper>
    </>
  );
};
