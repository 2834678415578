import React, { FC, useCallback, useMemo, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { number } from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';
import clsx from 'clsx';
import { sample } from 'lodash';

import {
  AbsoluteSpinner,
  Autocomplete,
  CreatableAutocomplete,
  DatePicker,
  EmptyState,
  LoadingButton,
  NumberTextField,
  Tooltip,
} from 'components';
import { Milestones, ProjectCommission, ProjectPMAccessDate } from './components';
import { BillableLeaves } from 'views/ProjectDetail';
import { useCurrenciesQuery, useProjectManagersQuery } from 'generated/graphql';
import { getFullName, graphqlOnError, removeUTCTimezone, sortByField, submitForm } from 'utils';
import { useCreatableClientData, useErrorMsgBuilder, usePermissions } from 'hooks';
import { useAuth } from 'contexts';
import {
  ActionsType,
  Client,
  CompanyUser,
  Currency,
  Member,
  ProjectAccess,
  ProjectType,
  RateUnit,
  Scalars,
} from 'generated/types';
import { ASC, colorsPalette, NAME } from 'consts';
import { UsersBuilder } from 'utils/usersBuilder';
import { DashIcon, InfoIcon } from 'icons';

import { ColorPicker } from '../ColorPicker';
import { ScrollToError } from '../ScrollToError';

import styles from './NewProject.module.scss';

interface PMFinAccess {
  id: ProjectAccess;
  name: string;
  description: string;
}

export interface ProjectFormValues {
  type: { id: ProjectType; name: string };
  name: string;
  client: Client | null;
  unit: { id: RateUnit; name: string };
  color: string;
  fee_amount: number;
  fee_currency: Currency;
  pm: Member | null;
  start_date: string | Date;
  end_date: string | Date;
  pmFinAccess: PMFinAccess;
  pmFinAccessStart: string | Date | null;
  pmFinAccessEnd: string | Date | null;
  costBudgetAmount: number;
  commission: number | null;
  billableLeaves: boolean;
  overtimeMultiplier: number | null;
}

interface NewProjectProps {
  onSubmit: (values: ProjectFormValues) => void | Promise<void>;
  id?: string;
  client?: Client;
  pm?: Member;
  type?: ProjectType;
  unit?: RateUnit;
  name?: string;
  start_date?: string;
  end_date?: string;
  color?: string;
  submitLabel?: string;
  feeCurrencyId?: string | null;
  fee_amount?: number | null;
  commission?: number | null;
  overtimeMultiplier?: number | null;
  billableLeaves?: boolean;
  pmFinAccessId?: ProjectAccess;
  pmFinAccessStart?: string;
  pmFinAccessEnd?: string;
  onCancel: () => void;
  costBudgetAmount?: number | null;
}

export const NewProject: FC<NewProjectProps> = ({
  id,
  type,
  name,
  pm,
  start_date,
  end_date,
  unit,
  fee_amount,
  commission,
  billableLeaves,
  overtimeMultiplier,
  feeCurrencyId,
  submitLabel,
  client,
  color,
  pmFinAccessId,
  pmFinAccessStart,
  pmFinAccessEnd,
  onSubmit,
  onCancel,
  costBudgetAmount,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId: id });
  const { hasAccess: hasGeneralAccess } = usePermissions();
  const [isAdditionalDataUnsaved, setIsAdditionalDataUnsaved] = useState(false);
  const onChangeIsAdditionalDataUnsaved = useCallback((value: boolean) => setIsAdditionalDataUnsaved(value), []);

  const projectTypes = useMemo(
    () => [
      { id: ProjectType.TimeAndMaterial, name: t('projectType.timeAndMaterial') },
      { id: ProjectType.FixedPrice, name: t('projectType.fixed') },
      { id: ProjectType.NonBillable, name: t('projectType.nonBillable') },
      { id: ProjectType.Retainer, name: t('projectType.retainer') },
    ],
    [],
  );

  const pmFinAccessTypes = useMemo(
    () => [
      {
        id: ProjectAccess.NonAccess,
        name: t('forms.newProject.financialAccess.none'),
        description: t('forms.newProject.financialAccess.noneDescription'),
      },
      {
        id: ProjectAccess.RatesAccess,
        name: t('forms.newProject.financialAccess.ratesOnly'),
        description: t('forms.newProject.financialAccess.ratesOnlyDescription'),
      },
      {
        id: ProjectAccess.FullAccess,
        name: t('forms.newProject.financialAccess.fullAccess'),
        description: t('forms.newProject.financialAccess.fullAccessDescription'),
      },
    ],
    [],
  );

  const nonBillablePmFinAccessTypes = [
    {
      id: ProjectAccess.FullAccess,
      name: t('shareProject.accessOptions.fullAccess'),
      description: t('shareProject.accessOptions.fullAccessOnlySalaryDescription'),
    },
    {
      id: ProjectAccess.NonAccess,
      name: t('shareProject.accessOptions.none'),
      description: t('shareProject.accessOptions.noneOnlySalaryDescription'),
    },
  ];

  const getPmFinAccessTypes = (type: ProjectType) =>
    type === ProjectType.NonBillable ? nonBillablePmFinAccessTypes : pmFinAccessTypes;

  const rateUnits = useMemo(
    () => [
      { id: RateUnit.Hour, name: t('rateUnit.hour') },
      { id: RateUnit.Day, name: t('rateUnit.day') },
      { id: RateUnit.Month, name: t('rateUnit.month') },
    ],
    [],
  );

  const { clients, clientsLoading, getCreatedClient } = useCreatableClientData();

  const { data: { users = [] as Member[] } = {}, loading: projectManagersLoading } = useProjectManagersQuery({
    skip: !hasAccess(ActionsType.CreateProjects),
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const { data: { currencies = [] as Currency[] } = {}, loading: currenciesLoading } = useCurrenciesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const findCurrency = (id?: string | null) => currencies.find((currency) => currency.id === id);

  const getManagersOptionName = ({ first_name, last_name }: Member) =>
    getFullName(first_name, last_name, t('notApplicable'));

  const getCurrencyLabel = (currency: Currency) => `${currency.code.toUpperCase()} - ${t(`currency.${currency.code}`)}`;

  const sortedClient = useMemo(() => sortByField(clients, ASC, NAME), [clients]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        client: Yup.object()
          .nullable()
          .when(['type'], {
            is: (type: { id: ProjectType; name: string }) => {
              return type.id !== ProjectType.NonBillable;
            },
            then: Yup.object().nullable().required(t('forms.newProject.clientRequiredError')),
          }),
        type: Yup.object().nullable().required(t('forms.newProject.projectTypeError')),
        name: Yup.string()
          .required(t('forms.newProject.projectNameRequired'))
          .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
        pm: Yup.object().nullable().required(t('forms.newProject.projectManagerRequiredError')),
        pmFinAccess: Yup.object().nullable().required(t('forms.newProject.financialAccess.requiredError')),
        pmFinAccessStart: Yup.string().nullable(),
        pmFinAccessEnd: Yup.string().nullable(),
        start_date: Yup.string().nullable().required(t('forms.newProject.startDateRequired')),
        end_date: Yup.string().nullable().required(t('forms.newProject.endDateRequired')),
        unit: Yup.object().nullable().required(t('forms.newProject.unitRequiredError')),
        fee_amount: Yup.number().when(['type'], {
          is: (type: { id: ProjectType }) => type.id === ProjectType.FixedPrice,
          then: Yup.number()
            .nullable()
            .required(t('validation.required'))
            .typeError(t('forms.newProject.costDecimal'))
            .test('maxDigitsAfterDecimal', t('forms.newProject.costDecimal'), (number) =>
              /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
            )
            .positive(t('forms.newProject.costPositiveError')),
          otherwise: Yup.number().nullable(),
        }),

        fee_currency: Yup.object().nullable().required(t('forms.newProject.currencyRequiredError')),
        costBudgetAmount: Yup.number().when(['type'], {
          is: (type: { id: ProjectType }) => type.id === ProjectType.NonBillable,
          then: Yup.number()
            .nullable()
            .required(t('validation.required'))
            .typeError(t('forms.newProject.budgetDecimal')),
          otherwise: Yup.number().nullable(),
        }),
        overtimeMultiplier: number()
          .nullable()
          .positive('validation.positive')
          .min(0, t('validation.min', { digits: 0 }))
          .max(100, t('validation.max', { max: 100 }))
          .test(
            'maxDigitsAfterDecimal',
            t('validation.decimal', { digits: 2 }),
            (number) => !number || /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
          ),
        commission: number()
          .nullable()
          .positive('validation.positive')
          .min(0, t('validation.min', { digits: 0 }))
          .max(100, t('validation.max', { max: 100 }))
          .test(
            'maxDigitsAfterDecimal',
            t('validation.decimal', { digits: 2 }),
            (number) => !number || /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
          ),
      }),
    [],
  );

  const currencyTemplate = (selectedType: ProjectType) => {
    switch (selectedType) {
      case ProjectType.FixedPrice: {
        return (
          <div className={styles.twoItemsBox}>
            <div className={clsx(styles.unitField, 'flex-1')}>
              <InputLabel required>{t('forms.newProject.projectTotal')}</InputLabel>
              <Field>
                {({
                  form: {
                    handleChange,
                    handleBlur,
                    values: { fee_amount },
                    touched,
                    errors,
                    submitCount,
                  },
                }: FieldProps<ProjectFormValues['fee_amount'], ProjectFormValues>) => (
                  <NumberTextField
                    error={Boolean((submitCount || touched.fee_amount) && errors.fee_amount)}
                    helperText={(submitCount || touched.fee_amount) && t(errors.fee_amount!)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="fee_amount"
                    value={fee_amount}
                    placeholder="0"
                    className="mb-24"
                  />
                )}
              </Field>
            </div>
            <div className={clsx(styles.unitField, 'flex-1')}>
              <InputLabel required>{t('forms.newProject.currency')}</InputLabel>
              <Field>
                {({
                  form: {
                    setValues,
                    handleBlur,
                    values: { fee_currency },
                    values,
                    touched,
                    errors,
                  },
                }: FieldProps<ProjectFormValues['fee_currency'], ProjectFormValues>) => (
                  <Autocomplete
                    placeholder={t('forms.newProject.currency')}
                    className="mb-24"
                    value={fee_currency}
                    name="fee_currency"
                    onBlur={handleBlur}
                    disableClearable
                    error={touched.fee_currency ? errors.fee_currency : undefined}
                    getOptionLabel={getCurrencyLabel}
                    options={currenciesLoading && fee_currency ? fee_currency : currencies}
                    onChange={(fee_currency: ProjectFormValues['fee_currency']) =>
                      setValues({ ...values, fee_currency })
                    }
                  />
                )}
              </Field>
            </div>
          </div>
        );
      }
      case ProjectType.NonBillable: {
        return (
          <div>
            <InputLabel>{t('forms.newProject.budget')}</InputLabel>
            <Field>
              {({
                form: {
                  handleChange,
                  handleBlur,
                  values: { costBudgetAmount },
                  touched,
                  errors,
                  submitCount,
                },
              }: FieldProps<ProjectFormValues['costBudgetAmount'], ProjectFormValues>) => (
                <NumberTextField
                  error={Boolean((submitCount || touched.costBudgetAmount) && errors.costBudgetAmount)}
                  helperText={(submitCount || touched.costBudgetAmount) && t(errors.costBudgetAmount!)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="costBudgetAmount"
                  value={costBudgetAmount}
                  placeholder="0"
                  className="mb-24"
                />
              )}
            </Field>
          </div>
        );
      }
      default: {
        return (
          <>
            <div className={styles.twoItemsBox}>
              <div className={clsx(styles.unitField, 'flex-1')}>
                <InputLabel required>{t('forms.newProject.currency')}</InputLabel>
                <Field>
                  {({
                    form: {
                      setValues,
                      handleBlur,
                      values: { fee_currency },
                      values,
                      touched,
                      errors,
                    },
                  }: FieldProps<ProjectFormValues['fee_currency'], ProjectFormValues>) => (
                    <Autocomplete
                      placeholder={t('forms.newProject.currency')}
                      className="mb-24"
                      value={fee_currency}
                      name="fee_currency"
                      onBlur={handleBlur}
                      disableClearable
                      error={touched.fee_currency ? errors.fee_currency : undefined}
                      getOptionLabel={getCurrencyLabel}
                      options={currenciesLoading && fee_currency ? fee_currency : currencies}
                      onChange={(fee_currency: ProjectFormValues['fee_currency']) =>
                        setValues({ ...values, fee_currency })
                      }
                    />
                  )}
                </Field>
              </div>
              <div className={clsx(styles.unitField, 'flex-1')}>
                <InputLabel required>{t('forms.newProject.unit')}</InputLabel>
                <Field>
                  {({
                    form: {
                      setValues,
                      handleBlur,
                      values: { unit },
                      values,
                      touched,
                      errors,
                    },
                  }: FieldProps<ProjectFormValues['unit'], ProjectFormValues>) => (
                    <Autocomplete
                      placeholder={t('forms.newProject.unit')}
                      className="mb-24"
                      value={unit}
                      name="unit"
                      onBlur={handleBlur}
                      disableClearable
                      error={touched.unit ? errors.unit : undefined}
                      options={rateUnits}
                      onChange={(unit: ProjectFormValues['unit']) => setValues({ ...values, unit })}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={styles.twoItemsBox}>
              <div className="flex-1 mb-24">
                <InputLabel>{t('forms.newProject.currentCommission')}</InputLabel>
                <Field>
                  {({
                    form: {
                      handleBlur,
                      handleChange,
                      values: { commission },
                      touched,
                      errors,
                    },
                  }: FieldProps<ProjectFormValues['commission'], ProjectFormValues>) => (
                    <NumberTextField
                      error={touched.commission && !!errors.commission}
                      helperText={touched.commission && t(errors.commission!)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="commission"
                      placeholder="0"
                      value={commission}
                      disabled={!!id}
                      InputProps={{
                        endAdornment: <span className={styles.endAdornment}>%</span>,
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="flex-1 mb-24">
                <InputLabel>{t('forms.newProject.overtimeMultiplier')}</InputLabel>
                <Field>
                  {({
                    form: {
                      handleBlur,
                      handleChange,
                      values: { overtimeMultiplier },
                      touched,
                      errors,
                    },
                  }: FieldProps<ProjectFormValues['overtimeMultiplier'], ProjectFormValues>) => (
                    <NumberTextField
                      error={touched.overtimeMultiplier && !!errors.overtimeMultiplier}
                      helperText={touched.overtimeMultiplier && t(errors.overtimeMultiplier!)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="overtimeMultiplier"
                      value={overtimeMultiplier}
                      placeholder={t('forms.newProject.overtimeMultiplierPlaceholder')}
                      InputProps={{
                        endAdornment: (
                          <span className={styles.endAdornment}>
                            <Tooltip alwaysShowTooltip title={t('forms.newProject.overtimeMultiplierDescription')!}>
                              <InfoIcon />
                            </Tooltip>
                          </span>
                        ),
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
          </>
        );
      }
    }
  };

  const projectManagers = useCallback(() => {
    const usersBuilder = new UsersBuilder(users as CompanyUser[]);

    usersBuilder.sortMembersByName();
    usersBuilder.buildRestoredUsers();
    usersBuilder.buildNonEmployeeUsers();

    return usersBuilder.getUsers().map(({ member }) => member);
  }, [users]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    const client = await getCreatedClient(values.client);
    submitForm({ ...values, client }, setSubmitting, onSubmit);
  }, []);

  const initialCurrency = useMemo(
    () => (feeCurrencyId ? findCurrency(feeCurrencyId) : findCurrency(userData?.company.primaryCurrencyId))!,
    [currencies],
  );

  if (isPermissionsLoading || currenciesLoading) return <AbsoluteSpinner />;

  if (
    !hasAccess(ActionsType.EditActiveProjects) &&
    !hasAccess(ActionsType.EditArchivedProjects) &&
    !hasAccess(ActionsType.CreateProjects)
  ) {
    return <EmptyState className="mt-40" title="permission.denied" />;
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        client: client ?? null,
        type: projectTypes.find((typeItem) => typeItem.id === type) ?? projectTypes[0],
        pm: pm ?? null,
        name: name ?? '',
        color: color ?? sample(colorsPalette)!,
        start_date: start_date ? removeUTCTimezone(start_date) : new Date(),
        end_date: end_date ? removeUTCTimezone(end_date) : new Date(),
        unit: rateUnits.find((unitItem) => unitItem.id === unit) ?? rateUnits[0],
        fee_amount: fee_amount || null,
        fee_currency: initialCurrency,
        pmFinAccess: pmFinAccessTypes.find((item) => item.id === pmFinAccessId) ?? pmFinAccessTypes[0],
        pmFinAccessStart: pmFinAccessStart ? removeUTCTimezone(pmFinAccessStart) : null,
        pmFinAccessEnd: pmFinAccessEnd ? removeUTCTimezone(pmFinAccessEnd) : null,
        costBudgetAmount: costBudgetAmount || null,
        commission: commission || null,
        billableLeaves: billableLeaves || false,
        overtimeMultiplier: overtimeMultiplier || null,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitCount, touched, errors, values, setFieldValue, setValues, handleBlur }) => (
        <Form className="form">
          <div className="pb-24">
            <div className="flex">
              <ColorPicker color={values.color} onChange={(color: string) => setFieldValue('color', color)} />
              <div className="ml-16 flex-1">
                <InputLabel required>{t('forms.newProject.projectName')}</InputLabel>
                <Field component={TextField} name="name" type="text" className="mb-24" />
              </div>
            </div>
            <InputLabel className={clsx(values?.type.id !== ProjectType.NonBillable && 'required')}>
              {t('forms.newProject.client')}
            </InputLabel>
            <Field>
              {({
                form: {
                  values: { client },
                },
              }: FieldProps<ProjectFormValues['client'], ProjectFormValues>) => (
                <CreatableAutocomplete
                  placeholder={t('forms.newProject.selectClient')}
                  className="mb-24"
                  name="client"
                  onBlur={handleBlur}
                  value={client}
                  error={touched.client ? errors.client : undefined}
                  options={clientsLoading && client ? [client] : sortedClient}
                  onChange={(client: ProjectFormValues['client']) => setValues({ ...values, client })}
                  enableCreation={hasGeneralAccess(ActionsType.CreateClients)}
                />
              )}
            </Field>

            <div className={styles.datePickersBox}>
              <div className="flex-1 mb-24">
                <InputLabel required>{t('forms.newProject.startDate')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { start_date },
                    },
                  }: FieldProps<ProjectFormValues['start_date'], ProjectFormValues>) => (
                    <DatePicker
                      value={start_date}
                      error={Boolean(submitCount && errors.start_date)}
                      helperText={!!submitCount && errors.start_date}
                      onChange={(start_date: Scalars['DateTime']) =>
                        setValues({
                          ...values,
                          start_date,
                        })
                      }
                    />
                  )}
                </Field>
              </div>
              <div className="pt-36">
                <DashIcon />
              </div>
              <div className="flex-1 mb-24">
                <InputLabel required>{t('forms.newProject.endDate')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { end_date },
                    },
                  }: FieldProps<ProjectFormValues['end_date'], ProjectFormValues>) => (
                    <DatePicker
                      value={end_date}
                      error={Boolean(submitCount && errors.end_date)}
                      helperText={!!submitCount && errors.end_date}
                      onChange={(end_date: Scalars['DateTime']) =>
                        setValues({
                          ...values,
                          end_date,
                        })
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={styles.pmBox}>
              <div className="flex-1">
                <InputLabel required>{t('forms.newProject.projectManagerLabel')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { pm },
                    },
                  }: FieldProps<ProjectFormValues['pm'], ProjectFormValues>) => (
                    <Autocomplete
                      value={pm}
                      error={touched.pm ? errors.pm : undefined}
                      name="pm"
                      onBlur={handleBlur}
                      placeholder={t('forms.newProject.projectManagerPlaceholder')}
                      getOptionLabel={getManagersOptionName}
                      options={projectManagersLoading && pm ? pm : projectManagers()}
                      onChange={(pm: ProjectFormValues['pm']) => setValues({ ...values, pm })}
                      disabled={!hasAccess(ActionsType.ShareProject)}
                    />
                  )}
                </Field>
              </div>

              {hasAccess(ActionsType.ShareProject) && (
                <div className="flex-1">
                  <InputLabel required>{t('forms.newProject.financialAccess.label')}</InputLabel>
                  <Field>
                    {({
                      form: {
                        values: { type, pmFinAccess },
                      },
                    }: FieldProps<ProjectFormValues['pmFinAccess'], ProjectFormValues>) => (
                      <Autocomplete
                        value={pmFinAccess}
                        error={touched.pmFinAccess ? errors.pmFinAccess : undefined}
                        name="pmFinAccess"
                        onBlur={handleBlur}
                        placeholder={t('forms.newProject.financialAccess.placeholder')}
                        options={getPmFinAccessTypes(type.id)}
                        renderOption={(item: ProjectFormValues['pmFinAccess']) => (
                          <div className="flex flex-column">
                            {item.name}
                            <span className={styles.selectOptionDescription}>{item.description}</span>
                          </div>
                        )}
                        onChange={(pmFinAccess: ProjectFormValues['pmFinAccess']) =>
                          setValues({ ...values, pmFinAccess })
                        }
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
            {hasAccess(ActionsType.ShareProject) && (
              <ProjectPMAccessDate submitCount={submitCount} errors={errors} setValues={setValues} values={values} />
            )}

            <h2 className={styles.sectionTitle}>
              {values.type.id === ProjectType.NonBillable
                ? t('forms.newProject.financeSettings')
                : t('forms.newProject.financeBillingSettings')}
            </h2>
            {hasAccess(ActionsType.EditProjectRatecards) && (
              <>
                <InputLabel required>{t('forms.newProject.projectType')}</InputLabel>
                <Field>
                  {({
                    form: {
                      values: { type },
                    },
                  }: FieldProps<ProjectFormValues['type'], ProjectFormValues>) => (
                    <Autocomplete
                      value={type}
                      name="type"
                      onBlur={handleBlur}
                      error={touched.type ? errors.type : undefined}
                      placeholder={t('forms.newProject.projectType')}
                      className="mb-24"
                      options={projectTypes}
                      disableClearable
                      onChange={(type: ProjectFormValues['type']) => {
                        if (
                          type.id === ProjectType.NonBillable &&
                          values.pmFinAccess.id === ProjectAccess.RatesAccess
                        ) {
                          return setValues({ ...values, type, pmFinAccess: nonBillablePmFinAccessTypes[1] });
                        }
                        setValues({ ...values, type });
                      }}
                    />
                  )}
                </Field>
              </>
            )}
            {hasAccess(ActionsType.EditProjectRatecards) && currencyTemplate(values?.type?.id as ProjectType)}
            {id && type !== ProjectType.NonBillable && (
              <ProjectCommission
                projectId={id}
                onChangeIsCommissionsUnsaved={onChangeIsAdditionalDataUnsaved}
                onChangeCurrentCommission={(value) => setFieldValue('commission', value)}
                currentCommission={values.commission || 0}
              />
            )}
            {id && values.type.id !== ProjectType.NonBillable && (
              <BillableLeaves
                projectId={id}
                isBillableLeaves={values.billableLeaves}
                onChangeIsBillableLeaves={() => setFieldValue('billableLeaves', !values.billableLeaves)}
                onChangeIsLeavesUnsaved={onChangeIsAdditionalDataUnsaved}
              />
            )}
            {id && type === ProjectType.FixedPrice && hasAccess(ActionsType.ProjectMilestones) && (
              <Milestones
                projectId={id}
                onChangeIsMilestonesUnsaved={onChangeIsAdditionalDataUnsaved}
                onChangeProjectTotal={(value) => {
                  if (value !== values.fee_amount) {
                    setFieldValue('fee_amount', value);
                  }
                }}
              />
            )}
          </div>
          <div className="controls">
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              className="mr-8"
              disabled={
                (isAdditionalDataUnsaved && values.billableLeaves) ||
                (isAdditionalDataUnsaved && values.type.id === ProjectType.FixedPrice)
              }
            >
              {submitLabel ?? t('forms.create')}
            </LoadingButton>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              {t('forms.cancel')}
            </Button>
          </div>
          <ScrollToError isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
