import { useCreateFileFolderMutation, useFoldersQuery } from 'generated/graphql';
import { FileFolderDataFragment } from 'generated/types';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { ApolloError, Reference } from '@apollo/client';
import { useAuth } from 'contexts';

interface Props {
  projectId?: string;
  folderId?: string;
  skip?: boolean;
}

export const useCreatableFileFolderData = ({ projectId, folderId, skip }: Props) => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const { data: { folders = [] } = {}, loading: foldersLoading } = useFoldersQuery({
    onError(err: ApolloError) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id, projectId },
    skip: !userData!.company.id || skip,
  });

  const [createFileFolder] = useCreateFileFolderMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createFileFolder) return;

      cache.modify({
        fields: {
          folders(items: Reference[] = []) {
            return [...items, data.createFileFolder];
          },
        },
      });
    },
  });

  const getCreatedFolder = async (value: FileFolderDataFragment | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createFileFolder({
      variables: {
        data: { name: value.name, rootFolder: folderId },
        projectId: projectId || '',
        companyId: userData!.company.id,
      },
    });

    return data?.createFileFolder || null;
  };

  return { folders, foldersLoading, getCreatedFolder };
};
