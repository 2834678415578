import {
  useCompensationTypesQuery,
  useCreateCompensationTypeMutation,
  useDeleteCompensationTypeMutation,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from 'hooks';
import { Reference } from '@apollo/client';
import { useAuth } from 'contexts';
import { CompensationType } from 'generated/types';
import { useCallback } from 'react';

export const useCreatableCompensationTypesData = () => {
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();

  const {
    data: { compensationTypes = [] } = {},
    loading: compensationTypeLoading,
    refetch,
  } = useCompensationTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: { companyId: userData!.company.id },
  });

  const [createCompensationType] = useCreateCompensationTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (!data?.createCompensationType) return;

      cache.modify({
        fields: {
          compensationTypes(items: Reference[] = []) {
            return [...items, data.createCompensationType];
          },
        },
      });
    },
  });

  const [deleteCompensationType] = useDeleteCompensationTypeMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted() {
      refetch();
    },
  });

  const getCreatedCompensationType = useCallback(async (value: Pick<CompensationType, 'id' | 'name'> | null) => {
    if (value?.id) {
      return value;
    }

    if (!value?.name) {
      return null;
    }

    const { data } = await createCompensationType({
      variables: {
        data: { name: value?.name || '' },
        companyId: userData!.company.id,
      },
    });

    return data?.createCompensationType || null;
  }, []);

  const onDeleteCompensationTypes = useCallback(async (id: string) => {
    await deleteCompensationType({
      variables: {
        compensationTypeId: id,
        companyId: userData!.company.id,
      },
    });
  }, []);

  return { compensationTypes, compensationTypeLoading, getCreatedCompensationType, onDeleteCompensationTypes };
};
