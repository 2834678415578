import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, NumberTextField } from 'components';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import * as Yup from 'yup';
import { DAYS_IN_YEAR } from 'consts';
import { graphqlOnError } from 'utils';
import { useFormik } from 'formik';
import { BillableLeaveRuleAccrualType, LeaveType } from 'generated/types';
import { useLeaveTypesQuery } from 'generated/graphql';
import { useAuth } from 'contexts';
import { useErrorMsgBuilder } from 'hooks';
import { CancelCircle, SubmitCircle } from 'icons';

import styles from './styles.module.scss';

export type FormValues = {
  leaveTypes: Pick<LeaveType, 'name' | 'id'>[] | null;
  limit: number | null;
  accrualDate: { name: string; id: string } | null;
};

interface Props {
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  initialValue?: FormValues;
  usedLeaveRulesId?: string[];
  loading?: boolean;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LeaveRuleForm: FC<Props> = ({ initialValue, loading, onSubmit, onCancel, usedLeaveRulesId }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();

  const { data: { leaveTypes = [] } = {} } = useLeaveTypesQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
    },
  });

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      leaveTypes: Yup.array(Yup.object()).required(),
      limit: Yup.number()
        .nullable()
        .typeError(t('validation.validNumber'))
        .min(0, t('validation.min', { digits: 0 }))
        .max(DAYS_IN_YEAR, t('validation.max', { max: DAYS_IN_YEAR }))
        .required(t('validation.required'))
        .test('maxDigitsAfterDecimal', t('validation.decimal', { digits: 2 }), (number) =>
          /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
        ),
      accrualDate: Yup.object().nullable().required(t('validation.required')),
    });
  }, []);

  const monthOptions = useMemo(() => {
    const assignmentStartMonthOption = {
      name: `${t('viewProjectDetail.billableLeaves.member')} ▸ ${t('viewProjectDetail.billableLeaves.startDate')}`,
      id: BillableLeaveRuleAccrualType.AssignmentStartMonth,
    };
    const defaultOptions = months.map((value) => ({
      name: t(`common.months.${value}`),
      id: value,
    }));
    return [assignmentStartMonthOption, ...defaultOptions];
  }, []);

  const { handleBlur, handleSubmit, handleChange, values, setValues, touched, errors } = useFormik<FormValues>({
    initialValues: initialValue || {
      leaveTypes: [],
      limit: null,
      accrualDate: null,
    },
    validationSchema,
    onSubmit,
    validateOnChange: true,
  });

  const leaveOptions = useMemo(() => {
    const initialLeaveRulesValueId = initialValue?.leaveTypes?.map(({ id }) => id) || [];
    return leaveTypes.filter(({ id }) => !usedLeaveRulesId?.includes(id) || initialLeaveRulesValueId.includes(id));
  }, [initialValue, usedLeaveRulesId, leaveTypes]);

  return (
    <form className={styles.row}>
      <Autocomplete
        placeholder={t('viewProjectDetail.billableLeaves.chooseLeaveTypes')}
        name="leaveTypes"
        onBlur={handleBlur}
        multiple
        className={clsx(styles.item, styles.leaveType)}
        textFieldClassName={styles.leaveTypeInput}
        value={values.leaveTypes}
        error={touched.leaveTypes ? errors.leaveTypes : undefined}
        options={leaveOptions}
        filterSelectedOptions
        onChange={(leaveTypes: FormValues['leaveTypes']) => setValues({ ...values, leaveTypes })}
        classes={{ inputRoot: 'white-background' }}
        disableClearable
      />
      <NumberTextField
        placeholder="0"
        name="limit"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.limit?.toString()}
        className={clsx(styles.item, styles.limit)}
        error={touched.limit && !!errors.limit}
        helperText={touched.limit && errors.limit}
      />
      <Autocomplete
        placeholder={t('viewProjectDetail.billableLeaves.month')}
        name="accrualDate"
        onBlur={handleBlur}
        className={clsx(styles.item, styles.accrualDate)}
        value={values.accrualDate}
        error={touched.accrualDate ? errors.accrualDate : undefined}
        options={monthOptions}
        renderOption={(option: { id: string; name: string }) =>
          option.id === BillableLeaveRuleAccrualType.AssignmentStartMonth ? (
            <div>
              <span className={styles.secondaryColor}>{t('viewProjectDetail.billableLeaves.member')} ▸ </span>{' '}
              {t('viewProjectDetail.billableLeaves.startDate')}
            </div>
          ) : (
            option.name
          )
        }
        onChange={(accrualDate: FormValues['accrualDate']) => setValues({ ...values, accrualDate })}
        classes={{ inputRoot: 'white-background' }}
        disableClearable
      />
      <div className={styles.menu}>
        <IconButton size="small" disabled={loading} className={styles.iconButton} onClick={() => handleSubmit()}>
          <SubmitCircle className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles.iconButton} onClick={onCancel}>
          <CancelCircle className={styles.icon} />
        </IconButton>
      </div>
    </form>
  );
};
