import { ApolloClient, ApolloLink, from, fromPromise, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { localStorageManager } from 'services';
import { FAILED_TO_REFRESH_TOKEN, NON_REFRESHABLE_AUTH_ISSUE, REFRESH_OPERATION, REFRESHED_TOKEN, TOKEN } from 'consts';
import { AuthBus } from 'contexts';
import { isTokenExpiredError, isTokenInvalidError, notRefreshableAuthIssues } from 'utils';
import { RefreshDocument } from 'generated/graphql';
import { createUploadLink } from 'apollo-upload-client';
import { ResourcePlanningProject, ScenarioMembers } from 'generated/types';
import mixpanel from 'mixpanel-browser';

const uri = `${process.env.REACT_APP_API_URL}/graphql`;

const uploadLink = (createUploadLink({
  uri,
  headers: { 'Apollo-Require-Preflight': 'true' },
}) as unknown) as ApolloLink;

const authLink = setContext((_, { headers }) => {
  const token = localStorageManager.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    // catch error, related to 'bad token' (if on BE resolver is public, we're not going to receive this error)
    if (isTokenExpiredError(graphQLErrors) && operation.operationName !== REFRESH_OPERATION) {
      return fromPromise(
        client
          .mutate({
            mutation: RefreshDocument,
          })
          .catch(() => {
            AuthBus.emit(FAILED_TO_REFRESH_TOKEN);
          }),
      )
        .filter(Boolean)
        .flatMap((value) => {
          const { data } = value || {};
          const token = data?.refresh?.token;

          // shouldn't happen, but just in case
          if (!token) {
            AuthBus.emit(FAILED_TO_REFRESH_TOKEN);
          } else {
            AuthBus.emit(REFRESHED_TOKEN, token);
            const oldHeaders = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`,
              },
            });
          }

          return forward(operation);
        });
    }

    if (isTokenInvalidError(graphQLErrors)) {
      AuthBus.emit(FAILED_TO_REFRESH_TOKEN);
      return;
    }

    if (notRefreshableAuthIssues(graphQLErrors)) {
      AuthBus.emit(NON_REFRESHABLE_AUTH_ISSUE);
      response && (response.errors = undefined);
    }
  }

  graphQLErrors?.forEach(({ extensions, message }) => {
    mixpanel.track('Error message shown', {
      'Error code': extensions?.code,
      'Error message text': message,
    });
  });

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ScenarioMembers: {
        keyFields: (response) =>
          `ScenarioMembers:${response.id}-${(response as ScenarioMembers)?.scenarioMemberEntity?.length || 0}`,
      },
      ResourcePlanningMember: {
        keyFields: ['id', 'projects', ['id']],
      },
      ResourcePlanningProject: {
        keyFields: (response) =>
          `ResourcePlanningProject:${
            response.id +
            (response as ResourcePlanningProject).assignment.length.toString() +
            (response as ResourcePlanningProject).assignment
              ?.map(
                ({ id, startDate, endDate }: { id: string; startDate: string; endDate: string }) =>
                  `${id}${startDate}${endDate}`,
              )
              .join(' ') +
            (response as ResourcePlanningProject).requests?.map(({ id }: { id: string }) => id).join('')
          }`,
      },
      BillableLeaveRuleBalance: {
        keyFields: ['period', 'members', ['id']],
      },
      BalanceMember: {
        keyFields: (response) =>
          `BalanceMember:${response.memberId}-${response.period}-${response.limit}-${response.balance}`,
      },
      TotalTimeLog: {
        keyFields: (response) => `TotalTimeLog:${response.assignment_id}-${response.date}`,
      },
      ProjectAssignmentsList: {
        keyFields: (response) => `ProjectAssignmentsList:${response.totalRevenue}`,
      },
      ProjectMilestoneSection: {
        keyFields: ['id', 'plannedRevenue'],
      },
      Query: {
        fields: {
          requests: {
            keyArgs: ['requestFilter'],
            merge(existing, incoming, { args }) {
              if (!args?.offset) return incoming;

              return [...(existing || []), ...(incoming || [])];
            },
          },
          activityHistories: {
            keyArgs: ['historyFilter'],
            merge(existing, incoming) {
              return [...(existing || []), ...(incoming || [])];
            },
          },
        },
      },
    },
  }),
  // todo think if we need retryLink
  link: from([authLink, errorLink, uploadLink]),
  connectToDevTools: true,
});
