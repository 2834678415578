import React, { FC, ReactElement } from 'react';
import { DropdownMenu } from 'components';
import { MoreIcon } from 'icons';
import clsx from 'clsx';
import { IconButton, MenuItem } from '@material-ui/core';

import styles from './styles.module.scss';

interface MenuItem {
  onClickFunc: () => void;
  Icon?: ReactElement;
  title: string;
}

interface Props {
  menuItems: MenuItem[];
  className?: string;
}

export const ActionsDropdown: FC<Props> = ({ menuItems, className }) => (
  <DropdownMenu
    trigger={(handleClick, open) => (
      <IconButton
        size="small"
        className={clsx(styles.menuButton, open && styles.menuButtonActive, className)}
        onClick={handleClick}
      >
        <MoreIcon className={styles.menuIcon} />
      </IconButton>
    )}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    {(handleClose) =>
      menuItems.map((item) => (
        <MenuItem
          key={item.title}
          className="px-12"
          onClick={() => {
            handleClose();
            item.onClickFunc();
          }}
        >
          {item.Icon && <div className={styles.iconBox}>{item.Icon}</div>}
          {item.title}
        </MenuItem>
      ))
    }
  </DropdownMenu>
);
