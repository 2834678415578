import React from 'react';
import {
  Column,
  IdType,
  SortingRule,
  useColumnOrder,
  useExpanded,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  useSortBy,
  useTable,
} from 'react-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import { Body, EmptyState, Footer, Heading, IndeterminateCheckbox, Pagination } from './parts';
import { getTableSort, useTableColumnsOrder, useTableSelectedRows } from './hooks';
import { SELECTION } from 'consts';

import styles from './Table.module.scss';

export type ExpandedColumn<D extends Record<string, unknown> = Record<string, never>> = Column<D> & {
  show?: boolean;
  sortable?: boolean;
};

export interface TableProps<D extends Record<string, unknown> = Record<string, never>> {
  data: D[];
  columns: ExpandedColumn<D>[];
  isManualPagination?: boolean;
  pageCount?: number;
  pageSize?: number;
  pageIndex?: number;
  showPagination?: boolean;
  onSort?: () => void;
  onRowClick?: (rowId: string) => unknown;
  getRedirectRowLink?: (rowId: string) => string;
  getRowWarning?: (data: D) => boolean;
  search?: string;
  emptyStateLabel?: string;
  loading?: boolean;
  headingClassName?: string;
  showFooter?: boolean;
  sortByValue?: SortingRule<D>[] | null;
  columnOrder?: IdType<D>[] | null;
  onChangeTableSortBy?: (sortBy: SortingRule<D>) => void;
  stickyLeftSelection?: boolean;
  selectIdName?: string;
  onSelectRows?: (selections: string[]) => void;
  selectedRowIds?: Record<IdType<D>, boolean>;
  isUnselectedRowStyles?: boolean;
  insideAccordion?: boolean;
  className?: string;
  footerClassName?: string;
  stickyFooter?: boolean;
  isThreeActions?: boolean;
  emptyStateClassName?: string;
}

export const selectColumn = {
  id: SELECTION,
  Header: function header({
    getToggleAllRowsSelectedProps,
    selectedFlatRows,
    toggleAllRowsSelected,
  }: UseRowSelectInstanceProps<Record<string, unknown>>) {
    const checkboxProps = getToggleAllRowsSelectedProps();

    if (checkboxProps.indeterminate && !selectedFlatRows.length) {
      toggleAllRowsSelected(false);
    }

    return <IndeterminateCheckbox {...checkboxProps} />;
  },
  Cell: function cell({ row }: { row: UseRowSelectRowProps<Record<string, unknown>> }) {
    return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />;
  },
  maxWidth: 45,
  minWidth: 45,
};

export const Table = <D extends Record<string, unknown> = Record<string, never>>({
  data,
  columns,
  isManualPagination,
  className,
  footerClassName,
  pageCount: controlledPageCount,
  pageSize: controlledPageSize,
  pageIndex: controlledPageIndex,
  showPagination,
  emptyStateLabel,
  emptyStateClassName,
  loading,
  onRowClick,
  getRedirectRowLink,
  getRowWarning,
  headingClassName,
  showFooter,
  sortByValue,
  columnOrder,
  onChangeTableSortBy,
  insideAccordion,
  onSelectRows,
  selectedRowIds,
  selectIdName,
  stickyLeftSelection,
  isThreeActions,
  stickyFooter,
  isUnselectedRowStyles = true,
}: TableProps<D>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
    selectedFlatRows,
    setColumnOrder,
    rows,
  } = useTable(
    {
      columns,
      data,
      manualPagination: isManualPagination,
      disableMultiSort: true,
      autoResetPage: false,
      ...(isManualPagination && { pageCount: controlledPageCount }), // only if manualPagination is enable
      initialState: {
        ...(selectedRowIds && { selectedRowIds }),
        ...(isManualPagination && { pageIndex: controlledPageIndex }),
        ...(sortByValue?.[0].id && { sortBy: sortByValue }),
        pageSize: controlledPageSize || data.length,
        hiddenColumns: columns
          .filter(({ show }) => show === false)
          .map(({ accessor, id }) => id || accessor) as string[],
      },
    },
    useSortBy,
    useExpanded,
    useColumnOrder,
    useRowSelect,
    (hooks) => {
      onSelectRows && hooks.visibleColumns.push((columns) => [selectColumn, ...columns]);
    },
  );

  getTableSort({ sortBy, onChangeTableSortBy });
  useTableColumnsOrder({ columnOrder, setColumnOrder });
  useTableSelectedRows({ rows: selectedFlatRows, onSelectRows, selectIdName });

  if (loading) {
    return <CircularProgress size={64} style={{ color: '#24343D' }} />;
  }

  return (
    <div
      className={clsx(styles.table, insideAccordion && styles.insideAccordion, className, 'p-relative overflow-auto')}
    >
      <table {...getTableProps()} className="w-100">
        <Heading<D>
          headerGroups={headerGroups}
          headingClassName={headingClassName}
          insideAccordion={insideAccordion}
          isSelectMode={!!onSelectRows}
          stickyLeftSelection={stickyLeftSelection}
          isThreeActions={isThreeActions}
        />
        {data.length ? (
          <Body<D>
            onRowClick={onRowClick}
            getRedirectRowLink={getRedirectRowLink}
            getRowWarning={getRowWarning}
            getTableBodyProps={getTableBodyProps}
            rows={showPagination ? page : rows}
            prepareRow={prepareRow}
            showFooter={showFooter}
            isCouldBeSelected={!!onSelectRows && isUnselectedRowStyles}
            isSelectMode={!!onSelectRows}
            stickyLeftSelection={stickyLeftSelection}
          />
        ) : (
          <EmptyState label={emptyStateLabel} className={emptyStateClassName} />
        )}
        {showFooter && !!data.length && (
          <Footer
            footerGroups={footerGroups}
            footerClassName={footerClassName}
            isSelectMode={!!onSelectRows}
            stickyLeftSelection={stickyLeftSelection}
            stickyFooter={stickyFooter}
          />
        )}
      </table>
      {showPagination && (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};
