import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalRateDataFragment } from 'generated/types';
import { currencyToValue, graphqlOnError, valueToCurrency } from 'utils';
import {
  ExternalRatesDocument,
  useDeleteExternalRateMutation,
  useEditExternalRateMutation,
  useEditExternalRateSeniorityMutation,
} from 'generated/graphql';
import { toast } from 'react-toastify';
import { useCreateExternalRate, useErrorMsgBuilder } from 'hooks';
import { useAuth } from 'contexts';
import { ViewRate } from '../ViewRate';
import { FormValues, RateForm } from '../RateForm';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT, EMPTY_DATA_STATE } from 'consts';
import { isOnlySeniorityEdited } from '../../helpers';

interface Props {
  rate: ExternalRateDataFragment;
  hideSeniority?: boolean;
  currency?: string;
  disableAddRate?: boolean;
  onAddRate?: () => void;
}

export const RateItem: FC<Props> = ({ rate, hideSeniority, currency, disableAddRate, onAddRate }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const [isEdit, setIsEdit] = useState(false);
  const { id, seniority, start_date, end_date, rate_amount, unit, seniorityId, projectId, roleId } = rate;

  const [deleteRate] = useDeleteExternalRateMutation({
    onCompleted() {
      toast.success(t('rateCard.rate.deletedSuccessfully'));
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteExternalRate) });
        cache.gc();
      }
    },
    variables: { companyId: userData!.company.id, externalRateId: id, projectId: projectId },
    refetchQueries: ['projectAssignmentsList', 'profitability'],
  });

  const [editExternalRate] = useEditExternalRateMutation({
    onCompleted() {
      toast.success(t('rateCard.rate.editSuccessfully'));
      setIsEdit(false);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [
      {
        query: ExternalRatesDocument,
        variables: {
          companyId: userData!.company.id,
          projectId: projectId,
          isPast: true,
        },
      },
      {
        query: ExternalRatesDocument,
        variables: {
          companyId: userData!.company.id,
          projectId: projectId,
          isPast: false,
        },
      },
      'projectAssignmentsList',
      'profitability',
    ],
  });

  const [editExternalRateSeniority] = useEditExternalRateSeniorityMutation({
    onCompleted() {
      toast.success(t('rateCard.rate.editSenioritySuccessfully'));
      setIsEdit(false);
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: ['projectAssignmentsList', 'profitability'],
  });

  const createExternalRate = useCreateExternalRate({
    onCompleted: () => setIsEdit(false),
    projectId,
    companyId: userData!.company.id,
  });

  const handleSaveRate = (values: FormValues) => {
    const { start_date, end_date, unit, amount, seniority, new_start_date } = values;

    if ((seniorityId || '') !== (seniority?.id || '')) {
      editExternalRateSeniority({
        variables: {
          companyId: userData!.company.id,
          data: {
            newSeniorityId: seniority?.id || null,
            projectId: projectId,
            roleId: roleId,
            seniorityId: seniorityId,
          },
        },
      });

      if (isOnlySeniorityEdited(values, rate)) return;
    }

    if (!new_start_date) {
      editExternalRate({
        variables: {
          data: {
            start_date: format(new Date(start_date), DEFAULT_DATE_FORMAT),
            end_date: end_date ? format(new Date(end_date), DEFAULT_DATE_FORMAT) : '',
            rate_amount: Math.round(currencyToValue(amount || 0)),
            unit: unit.id,
          },
          projectId: projectId,
          companyId: userData!.company.id,
          externalRateId: id,
        },
      });

      return;
    }

    createExternalRate({
      variables: {
        companyId: userData!.company.id,
        projectId: projectId,
        roleId: roleId,
        seniorityId: seniority?.id || seniorityId,
        data: {
          start_date: format(new Date(new_start_date), DEFAULT_DATE_FORMAT),
          end_date: '',
          rate_amount: Math.round(currencyToValue(amount || 0)),
          unit: unit.id,
        },
      },
    });
  };

  return isEdit ? (
    <RateForm
      isEditing
      showSenioritySelect={!hideSeniority}
      onSubmit={handleSaveRate}
      onCancel={() => setIsEdit(false)}
      currencyCode={currency}
      unit={unit}
      initialValue={{
        seniority: { id: seniorityId || '', name: seniority || '' },
        unit: { id: unit, name: t(`rateCard.unit.${unit}`) },
        amount: valueToCurrency(rate_amount),
        start_date: start_date,
        end_date: end_date,
      }}
    />
  ) : (
    <ViewRate
      projectId={projectId}
      hideSeniority={hideSeniority}
      seniority={seniority || EMPTY_DATA_STATE}
      start_date={start_date}
      end_date={end_date}
      rate_amount={valueToCurrency(rate_amount)}
      unit={unit}
      rateCurrency={currency}
      onDelete={() => deleteRate()}
      onEdit={() => setIsEdit(true)}
      disableAddRate={disableAddRate}
      onAddRate={onAddRate}
    />
  );
};
