import { useAuth } from 'contexts';
import { ActionsType, IProjectDocumentFilters } from 'generated/types';
import { useErrorMsgBuilder, usePermissions } from 'hooks/index';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileFolderDataFragmentDoc,
  FoldersDocument,
  useCreateFileFolderMutation,
  useDeleteFileFolderMutation,
  useDeleteProjectDocumentMutation,
  useEditFileFolderMutation,
  useFolderContentsQuery,
  useProjectDocumentUrlLazyQuery,
} from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';

interface Props {
  projectId?: string;
  folderId?: string;
  searchByName?: string;
  filterData?: IProjectDocumentFilters;
}

export const useFilesData = ({ projectId, folderId, searchByName, filterData }: Props) => {
  const { userData } = useAuth();
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId });

  const onError = (err: ApolloError) => {
    graphqlOnError(err, tls(err.message));
  };

  const folderContentsVariables = {
    companyId: userData!.company.id,
    projectId,
    folderId,
    filters: filterData,
    searchByName,
  };

  const { data: { folderContents } = {}, loading: isFolderContentsLoading, refetch } = useFolderContentsQuery({
    onError,
    variables: folderContentsVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !userData!.company.id || !hasAccess(ActionsType.ViewDocuments),
  });

  const [deleteProjectDocument] = useDeleteProjectDocumentMutation({
    onError,
    onCompleted() {
      toast.success(t('projectFile.notifications.delete'));
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteProjectDocument) });
        cache.gc();
      }
    },
  });

  const [deleteFileFolder] = useDeleteFileFolderMutation({
    onError,
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify(data.deleteFileFolder) });
        cache.gc();
      }
    },
  });

  const [createFileFolder] = useCreateFileFolderMutation({
    onError,
    onCompleted() {
      refetch();
      toast.success(t('projectFile.notifications.createFolder'));
    },
    refetchQueries: [FoldersDocument],
  });

  const [editFileFolder] = useEditFileFolderMutation({
    onError,
    onCompleted() {
      toast.success(t('projectFile.notifications.editFolder'));
    },
    update(cache, { data }) {
      if (!data?.editFileFolder) return;

      cache.updateFragment(
        {
          id: `FileFolder:${data.editFileFolder.id}`,
          fragmentName: 'FileFolderData',
          fragment: FileFolderDataFragmentDoc,
        },
        () => ({ ...data.editFileFolder }),
      );
    },
  });

  const [fetchFileUrl] = useProjectDocumentUrlLazyQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      window.open(data.projectDocumentUrl, '_blank');
    },
    fetchPolicy: 'network-only',
  });

  const getFileUrl = useCallback((projectDocumentId: string, download = false) => {
    fetchFileUrl({
      variables: {
        projectDocumentId,
        companyId: userData!.company.id,
        download,
      },
    });
  }, []);

  const onCreateFolder = useCallback(({ name, rootFolder }: { name: string; rootFolder?: string | null }) => {
    createFileFolder({
      variables: {
        data: { name, rootFolder },
        projectId: projectId || '',
        companyId: userData!.company.id,
      },
    });
  }, []);

  const onEditFolder = useCallback(({ id, name }: { id: string; name: string }) => {
    editFileFolder({
      variables: {
        data: { name },
        projectId: projectId || '',
        companyId: userData!.company.id,
        fileFolderId: id,
      },
    });
  }, []);

  const onDeleteFile = useCallback((id: string, projectId: string) => {
    deleteProjectDocument({
      variables: { companyId: userData!.company.id, projectId, projectDocumentId: id },
    });
  }, []);

  const onDeleteFolder = useCallback((id: string, projectId: string, removeContents?: boolean) => {
    deleteFileFolder({
      variables: { companyId: userData!.company.id, projectId, fileFolderId: id, removeContents },
      onCompleted() {
        toast.success(t('projectFile.notifications.deleteFolder'));

        if (!removeContents) {
          refetch();
        }
      },
    });
  }, []);

  const data = [...(folderContents?.fileFolder || []), ...(folderContents?.projectDocument || [])];
  const fileCount = folderContents?.projectDocument?.length;
  const folderCount = folderContents?.fileFolder?.length;
  const foldersPosition = folderContents?.foldersPosition || [];
  const loading = isFolderContentsLoading || isPermissionsLoading;

  return {
    data,
    fileCount,
    folderCount,
    foldersPosition,
    loading,
    refetch,
    onCreateFolder,
    onEditFolder,
    onDeleteFile,
    onDeleteFolder,
    getFileUrl,
  };
};
