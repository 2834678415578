import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, DatePicker, DialogWrapper, LoadingButton, MemberTag, Portal, Radio } from 'components';
import { useExportMembersTimeLogsLazyQuery, useTeamMembersOptionsQuery } from 'generated/graphql';
import { downloadExcelXLS, getFullName, graphqlOnError, isAdminPermission, MembersBuilder } from 'utils';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import { useAuth } from 'contexts';
import { Button, RadioGroup } from '@material-ui/core';
import { useFormik } from 'formik';
import { addYears, endOfMonth, format, isSameMonth, isSameYear, startOfMonth } from 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import * as Yup from 'yup';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { MemberOptionDataFragment } from 'generated/types';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface FormValues {
  isAllMembersSelected: boolean;
  selectedMembers: MemberOptionDataFragment[] | null;
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export const ExportTimeLogsModal: FC = () => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isOpen, onOpen, onClose] = useIsOpen();

  const { data: { members = [] } = {}, loading } = useTeamMembersOptionsQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      isArchived: false,
    },
  });

  const getMemberOptionName = ({ first_name, last_name }: MemberOptionDataFragment) =>
    getFullName(first_name, last_name, t('notApplicable'));

  const teamMember = () => {
    const membersBuilder = new MembersBuilder(members);
    membersBuilder.sortMembersByName();

    return membersBuilder.getMembers();
  };

  const renderMemberTags = useCallback(
    (options: MemberOptionDataFragment[], getTagProps: AutocompleteGetTagProps) =>
      options.map(({ id, color, first_name, last_name }, index) => {
        const tagProps = getTagProps({ index });

        return (
          <MemberTag
            {...tagProps}
            key={id}
            firstName={first_name!}
            lastName={last_name!}
            avatarColor={color}
            className={clsx(styles.tag, styles.tagBorder, styles.tagSelected)}
          />
        );
      }),
    [],
  );

  const {
    handleSubmit,
    handleBlur,
    setValues,
    setFieldTouched,
    setFieldValue,
    submitCount,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: { isAllMembersSelected: true, selectedMembers: [], startDate: new Date(), endDate: new Date() },
    validationSchema: Yup.object({
      selectedMembers: Yup.array(Yup.object()).when(['isAllMembersSelected'], {
        is: (isAllMembersSelected: boolean) => !isAllMembersSelected,
        then: Yup.array(Yup.object()).test({
          message: t('validation.required'),
          test: (arr) => (arr?.length || 0) > 0,
        }),
        otherwise: Yup.array(Yup.object()).nullable(),
      }),
      startDate: Yup.date().nullable().required(t('insights.utilization.adminReport.reportPeriodRequired')),
      endDate: Yup.date()
        .nullable()
        .required(t('insights.utilization.adminReport.reportPeriodRequired'))
        .when('startDate', (val: string, schema: any) => {
          if (val) {
            const startDate = new Date(val);
            return (
              val && schema.max(addYears(startDate, 1), t('insights.utilization.adminReport.periodNoMoreThatYearError'))
            );
          }
        }),
    }),
    onSubmit: (data) => {
      const membersIds = data.isAllMembersSelected
        ? members.map(({ id }) => id)
        : (data.selectedMembers || []).map(({ id }) => id);

      exportTimeLogs({
        variables: {
          companyId: userData!.company.id,
          data: {
            start: format(startOfMonth(new Date(data.startDate!)), DEFAULT_DATE_FORMAT),
            end: format(endOfMonth(new Date(data.endDate!)), DEFAULT_DATE_FORMAT),
          },
          members: membersIds,
        },
      });
      onClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const getReportTitle = ({ startDate, endDate }: { startDate: string | Date; endDate: string | Date }): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const isSameYearPeriod = isSameYear(start, end);
    const isSameMonthPeriod = isSameMonth(start, end);
    const period = `${format(start, isSameYearPeriod ? 'MMMM' : 'MMMM, yyyy')}${isSameMonthPeriod ? '' : '–'}${format(
      end,
      isSameMonthPeriod ? ', yyyy' : 'MMMM, yyyy',
    )}`;
    const currentDate = format(new Date(), 'dd-MM-yyyy');

    return `${t('insights.utilization.adminReport.timeLogsReport')} - ${period} ${t(
      'insights.utilization.adminReport.asOf',
    )} ${currentDate}`;
  };

  const [exportTimeLogs, { loading: exportLoading }] = useExportMembersTimeLogsLazyQuery({
    onCompleted(data) {
      if (data?.exportMembersTimeLogs) {
        downloadExcelXLS(
          data.exportMembersTimeLogs,
          getReportTitle({
            startDate: values.startDate || '',
            endDate: values.endDate || '',
          }),
        );
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    fetchPolicy: 'no-cache',
  });

  // TODO: update isAdminPermission
  return (
    <>
      {userData && isAdminPermission(userData.type) ? (
        <Button className="ml-auto" variant="outlined" color="secondary" onClick={onOpen}>
          {t('insights.utilization.adminReport.exportTimeLogsReport')}
        </Button>
      ) : (
        ''
      )}

      <DialogWrapper
        open={isOpen}
        onClose={onClose}
        className={styles.dialog}
        contentClassName={styles.dialogContent}
        title={t('insights.utilization.adminReport.exportTimeLogsReport')}
      >
        <form className="px-24">
          <div className="flex flex-column gap-24">
            <div className="flex-1">
              <InputLabel required>{t('insights.utilization.adminReport.reportPeriod')}</InputLabel>
              <DatePicker
                showMonthYearPicker
                range
                placeholder={t('insights.utilization.adminReport.selectPeriod')}
                name="startDate"
                dateFormat="MMM yyyy"
                value={[values.startDate, values.endDate]}
                error={Boolean(submitCount && (errors.startDate || errors.endDate))}
                helperText={!!submitCount && (errors.startDate || errors.endDate)}
                onClick={() => setFieldTouched('startDate')}
                onChange={(dates) =>
                  setValues({
                    ...values,
                    startDate: dates?.[0] as FormValues['startDate'],
                    endDate: dates?.[1] as FormValues['endDate'],
                  })
                }
              />
            </div>

            <RadioGroup
              name="isAllMembersSelected"
              aria-label="change all members selected"
              onChange={(e) => setFieldValue('isAllMembersSelected', e.target.value === 'true')}
              value={values.isAllMembersSelected}
            >
              <Radio
                label={
                  <div onClick={() => setFieldValue('isAllMembersSelected', true)} className={styles.radioItem}>
                    {t('insights.utilization.adminReport.allMembers')}
                  </div>
                }
                value={true}
              />
              <Radio
                className="mt-8"
                label={
                  <div onClick={() => setFieldValue('isAllMembersSelected', false)} className={styles.radioItem}>
                    {t('insights.utilization.adminReport.selectedMembersOnly')}
                  </div>
                }
                value={false}
              />
              {!values.isAllMembersSelected && (
                <div className={styles.radioItemContent}>
                  <Autocomplete
                    placeholder={t('insights.utilization.adminReport.selectMembers')}
                    value={values.selectedMembers}
                    name="selectedMembers"
                    multiple
                    disableClearable
                    onBlur={handleBlur}
                    renderTags={renderMemberTags}
                    getOptionLabel={getMemberOptionName}
                    error={touched.selectedMembers && errors.selectedMembers}
                    options={loading ? [] : teamMember()}
                    noOptions={t('common.noOptions')}
                    onChange={(selectedMembers: FormValues['selectedMembers']) =>
                      setValues({ ...values, selectedMembers })
                    }
                  />
                </div>
              )}
            </RadioGroup>
          </div>
        </form>
        <Portal wrapperId="dialog-actions">
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('forms.cancel')}
          </Button>
          <LoadingButton loading={exportLoading} onClick={() => handleSubmit()}>
            {t('insights.utilization.adminReport.exportTimeLogsReport')}
          </LoadingButton>
        </Portal>
      </DialogWrapper>
    </>
  );
};
