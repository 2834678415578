import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, DialogWrapper, LoadingButton, NumberTextField, Portal } from 'components';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import { Button, TextField } from '@material-ui/core';
import { EditIcon } from 'icons';
import clsx from 'clsx';
import { BalanceMemberDataFragment, Scalars } from 'generated/types';
import { useFormik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import { DAYS_IN_YEAR, DEFAULT_DATE_FORMAT } from 'consts';
import { BillableLeaveRuleBalanceDocument, useEditMemberLeaveRuleMonthBalanceMutation } from 'generated/graphql';
import { graphqlOnError } from 'utils';
import { toast } from 'react-toastify';
import { useAuth } from 'contexts';
import { format } from 'date-fns';
import * as Yup from 'yup';

import styles from './styles.module.scss';

interface FormValues {
  accrualDate: Date | null;
  balance: number | null;
  notes: string;
}

interface Props {
  ruleId: string;
  balanceData: BalanceMemberDataFragment;
  refetch: () => void;
  onChangeIsOpenCustomizeBalance: (value: boolean) => void;
}

export const CustomizeBalanceCell: FC<Props> = ({ ruleId, balanceData, refetch, onChangeIsOpenCustomizeBalance }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [isOpen, onOpen, onClose] = useIsOpen();

  const [editMemberLeaveRuleMonthBalance] = useEditMemberLeaveRuleMonthBalanceMutation({
    onCompleted() {
      toast.success(t('viewProjectDetail.leavesBalance.customizeBalance.editMonthBalanceSuccessfully'));
      refetch();
      onClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [BillableLeaveRuleBalanceDocument],
  });

  const onSubmit = async (value: FormValues) => {
    await editMemberLeaveRuleMonthBalance({
      variables: {
        companyId: userData!.company.id,
        data: {
          accrualDate: format(value.accrualDate ? new Date(value.accrualDate) : new Date(), DEFAULT_DATE_FORMAT),
          balance: +(value.balance || 0),
          notes: value.notes,
          billableLeaveRuleId: ruleId,
          memberId: balanceData.memberId,
        },
      },
    });
  };

  const {
    handleSubmit,
    handleChange,
    submitCount,
    setValues,
    resetForm,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik<FormValues>({
    initialValues: { accrualDate: new Date(), balance: null, notes: '' },
    validationSchema: Yup.object({
      balance: Yup.number()
        .nullable()
        .typeError(t('validation.validNumber'))
        .min(0, t('validation.min', { digits: 0 }))
        .max(DAYS_IN_YEAR, t('validation.max', { max: DAYS_IN_YEAR }))
        .required(t('validation.required'))
        .test('maxDigitsAfterDecimal', t('validation.decimal', { digits: 2 }), (number) =>
          /^\d+(\.\d{1,2})?$/.test(number?.toString() as string),
        ),
      accrualDate: Yup.date().nullable().required(t('validation.required')),
      notes: Yup.string().nullable(),
    }),
    onSubmit,
  });

  useEffect(() => {
    onChangeIsOpenCustomizeBalance(isOpen);

    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className={styles.balance} onClick={onOpen}>
        {balanceData.balance}
        <span className={styles.secondaryColor}>/{balanceData.limit}</span>
        <EditIcon className={styles.editIcon} />
      </div>

      <DialogWrapper
        open={isOpen}
        onClose={onClose}
        className={styles.dialog}
        contentClassName={styles.dialogContent}
        title={t('viewProjectDetail.leavesBalance.customizeBalance.label')}
      >
        <form>
          <div className="mt-8 mb-16">
            <InputLabel>{t('viewProjectDetail.leavesBalance.customizeBalance.date')}</InputLabel>
            <DatePicker
              name="accrualDate"
              showMonthYearPicker
              dateFormat="MMMM yyyy"
              value={values.accrualDate}
              error={Boolean(submitCount && errors.accrualDate)}
              helperText={!!submitCount && errors.accrualDate}
              onChange={(accrualDate: Scalars['DateTime']) => setValues({ ...values, accrualDate })}
            />
          </div>

          <div className="mb-16">
            <InputLabel>{t('viewProjectDetail.leavesBalance.customizeBalance.newBalance')}</InputLabel>
            <NumberTextField
              placeholder="0"
              name="balance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.balance?.toString()}
              className={clsx(styles.item, styles.amount)}
              error={touched.balance && !!errors.balance}
              helperText={touched.balance && errors.balance}
              InputProps={{ className: styles.input }}
            />
          </div>

          <div className="mb-8">
            <InputLabel>{t('viewProjectDetail.leavesBalance.customizeBalance.reasonForCorrection')}</InputLabel>
            <TextField
              name="notes"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notes}
              placeholder={t('forms.typeNoteHere')}
            />
          </div>
        </form>

        <Portal wrapperId="dialog-actions">
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('forms.cancel')}
          </Button>
          <LoadingButton onClick={() => handleSubmit()}>{t('forms.saveAndClose')}</LoadingButton>
        </Portal>
      </DialogWrapper>
    </div>
  );
};
