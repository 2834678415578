import { Route } from 'react-router-hoc';
import { lazy } from 'react';
import { idRegEx } from 'utils/regex';

export const FilesRoute = Route(
  {
    folderId: Route.params.regex(idRegEx).optional,
    search: Route.query.string,
    filterByProjectId: Route.query.array(Route.query.string),
    filterByTagId: Route.query.array(Route.query.string),
  },
  ({ folderId }) => `/files/${folderId}`,
);

export const Files = FilesRoute(lazy(() => import(/* webpackChunkName: "Files" */ './Files')));
