import React, { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import styles from './styles.module.scss';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { ChevronIcon } from 'icons';
import { useTranslation } from 'react-i18next';

interface Data {
  id: string;
  name: string;
}

interface Props<T> {
  options?: T[];
  defaultValue?: T;
  onChange: (value: T | null) => void;
  enableCreation?: boolean;
  loading?: boolean;
  error?: string;
  name?: string;
}

export const CreatableAutocomplete = <T extends Data>({
  options = [],
  defaultValue,
  value,
  loading,
  onChange,
  enableCreation,
  error,
  name,
  placeholder,
  renderOption,
  ...autocompleteProps
}: Props<T> &
  Omit<AutocompleteProps<T, undefined, undefined, undefined>, 'onChange' | 'options' | 'error' | 'renderInput'>) => {
  const [selectedValue, setSelectedValue] = useState<T | null | undefined>(value || defaultValue);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValue(value || defaultValue);
  }, [value]);

  const handleChange = async (_event: ChangeEvent<unknown>, value: T | null) => {
    onChange(value);
    setSelectedValue(value);
  };

  const filterOptions = (options: T[], { inputValue }: FilterOptionsState<T>): T[] => {
    return [...options].filter(
      ({ id, name }) => selectedValue?.id !== id && name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
    );
  };

  const getCreateOptions = (options: T[], { inputValue }: FilterOptionsState<T>) => {
    const isItemInOptionsList = [...options, selectedValue].find((item) => item?.name === inputValue);
    if (inputValue.trim() !== '' && !isItemInOptionsList) {
      return {
        id: '',
        name: inputValue.trim(),
      } as T;
    }
  };

  const renderCreateOption = (option: T, state: AutocompleteRenderOptionState) =>
    option.id ? (
      <div className={styles.listItem}>{renderOption ? renderOption(option, state) : option.name}</div>
    ) : (
      <div className={styles.creatableListItem}>
        <span className={styles.createText}>{t('forms.create')}</span>
        {option.name}
      </div>
    );

  return (
    <Autocomplete
      {...autocompleteProps}
      getOptionSelected={(option, selected) => option?.id === selected?.id}
      value={selectedValue}
      filterOptions={(options: T[], params: FilterOptionsState<T>) => {
        const filtered = filterOptions(options, params);
        const createOptions = enableCreation && getCreateOptions(filtered, params);

        return createOptions ? [...filtered, createOptions] : filtered;
      }}
      classes={{
        inputRoot: styles.inputRoot,
        popperDisablePortal: styles.autocompletePopper,
        groupLabel: styles.groupLabel,
        listbox: styles.listBox,
        option: styles.option,
        noOptions: styles.noOptions,
      }}
      loading={loading}
      noOptionsText={enableCreation ? t('common.noCreatableOptions') : t('common.noOptions')}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      renderOption={renderCreateOption}
      popupIcon={<ChevronIcon />}
      ListboxProps={{ className: styles.listBox }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={placeholder}
          name={name}
          error={!!error}
          helperText={error}
          classes={{
            root: styles.textFieldRoot,
          }}
        />
      )}
    />
  );
};
