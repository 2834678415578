import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { MoreIcon } from 'icons';
import { IconButton } from '@material-ui/core';
import styles from './styles.module.scss';

export interface TableActionCellProps {
  children: ReactNode;
  isHovered?: boolean;
  disableStopPropagation?: boolean;
  className?: string;
}

export const TableActionCell = ({ children, isHovered, disableStopPropagation, className }: TableActionCellProps) => (
  <div onClick={(e) => !disableStopPropagation && e.stopPropagation()} className={clsx(styles.container, className)}>
    <div className={clsx(isHovered ? styles.childrenBox : styles.displayNone)}>{children}</div>

    <IconButton size="small" className={clsx(isHovered && styles.displayNone)}>
      <MoreIcon />
    </IconButton>
  </div>
);
