import React, { FC, memo } from 'react';
import { Integration } from 'icons';
import { Scalars } from 'generated/types';
import { useTranslation } from 'react-i18next';
import { getAcronym } from 'utils';
import { Avatar, Link, SIZES } from 'components';
import { Field } from './Field';
import styles from './styles.module.scss';
import { links } from 'App';

interface Item {
  data: Scalars['JSON'];
  changes?: Scalars['JSON'];
  createdByMemberName?: string | null;
  createdBy?: string | null;
  createdByMemberColor?: string | null;
  integration?: boolean | null;
}

interface Props {
  item: Item;
  enableNavigation?: boolean;
  isShowProjectField?: boolean;
  isShowProjectRoleField?: boolean;
  isShowMemberField?: boolean;
}

export const CommonItemDisplayFields: FC<Props> = memo(
  ({ item, enableNavigation, isShowProjectField, isShowProjectRoleField, isShowMemberField }) => {
    const { data, changes, createdByMemberName, createdBy, createdByMemberColor, integration } = item;
    const { t } = useTranslation();

    const creatorAcronym = getAcronym(...(createdByMemberName || '').split(' '));
    const teamMemberFirsName = data['memberFirstName'] || data['first_name'];
    const teamMemberLastName = data['memberLastName'] || data['last_name'];
    const projectId = data['projectId'];
    const projectName = data['project'] || data['name'] || data['projectName'];
    const projectRoleName = data['role'];
    const projectColor = changes?.['projectColor'] || data['projectColor'] || changes?.['color'] || data['color'];
    const memberId = data['memberId'];
    const memberColor = changes?.['memberColor'] || data['memberColor'] || changes?.['color'] || data['color'];

    return (
      <>
        {(createdByMemberName || integration) && (
          <Field
            label={t('activityHistory.logLabels.by')}
            value={
              integration ? (
                <>
                  <Integration className="mr-4" />
                  {t('activityHistory.integration')}
                </>
              ) : (
                <Link
                  disableNavigation={!enableNavigation}
                  to={createdBy ? links.ViewTeamMember({ id: createdBy }) : ''}
                  className={styles.link}
                  color="inherit"
                >
                  <Avatar avatarTitle={creatorAcronym} size={SIZES.xs} className="mr-4" color={createdByMemberColor} />
                  {createdByMemberName}
                </Link>
              )
            }
          />
        )}
        {projectName && isShowProjectField && (
          <Field
            label={t('activityHistory.logLabels.project')}
            value={
              <Link
                disableNavigation={!enableNavigation}
                to={projectId ? links.ProjectDetail({ id: projectId }) : ''}
                className={styles.link}
                color="inherit"
              >
                <span className={styles.projectColor} style={{ backgroundColor: projectColor }} />
                {projectName}
              </Link>
            }
          />
        )}
        {projectRoleName && isShowProjectRoleField && (
          <Field label={t('activityHistory.logLabels.projectRole')} value={projectRoleName} />
        )}
        {teamMemberFirsName && isShowMemberField && (
          <Field
            label={t('activityHistory.logLabels.teamMember')}
            value={
              <Link
                disableNavigation={!enableNavigation}
                to={memberId ? links.ViewTeamMember({ id: memberId }) : ''}
                className={styles.link}
                color="inherit"
              >
                <Avatar
                  avatarTitle={getAcronym(teamMemberFirsName, teamMemberLastName)}
                  size={SIZES.xs}
                  className="mr-4"
                  color={memberColor}
                />
                {`${teamMemberFirsName} ${teamMemberLastName}`}
              </Link>
            }
          />
        )}
      </>
    );
  },
);

CommonItemDisplayFields.displayName = 'CommonItemDisplayFields';
