import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DialogWrapper, ExpandedColumn, Table, TableActionCell, Tooltip, UserInfo } from 'components';
import { useBillableLeaveRuleBalanceQuery, useBillableLeavesRulesQuery } from 'generated/graphql';
import { getAcronym, getDateRangeFromType, graphqlOnError } from 'utils';
import { useErrorMsgBuilder, useIsOpen } from 'hooks';
import { useAuth } from 'contexts';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { BalanceMemberDataFragment, BillableLeaveRuleDataFragment } from 'generated/types';
import { ActivityLog as ActivityLogIcon, SettingsLink as SettingsIcon } from 'icons';
import { CellProps } from 'react-table';
import { ACTIONS, DATE, DEFAULT_DATE_FORMAT, LIMIT, MEMBER_NAME } from 'consts';
import clsx from 'clsx';
import { DateRangeTypes, TableCell } from 'types';
import { format } from 'date-fns';
import { LeavesConfiguration } from './components/LeavesConfiguration';
import Button from '@material-ui/core/Button';
import { MemberLeavesBalance } from './components/MemberLeavesBalance';
import { LeavesBalanceFilter } from './components/LeavesBalanceFilter';
import { AccrualMonthCell } from './components/AccrualMonthCell';
import { CustomizeBalanceCell } from './components/CustomizeBalanceCell';

import styles from './styles.module.scss';

interface Props {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  defaultLeaveRule?: string;
}

export const LeavesBalance: FC<Props> = ({ projectId, isOpen, onClose, defaultLeaveRule }) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const [selectedRuleId, setSelectedRuleId] = useState<string>(defaultLeaveRule || '');
  const [selectedRuleMonth, setSelectedRuleMonth] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRangeTypes | [Date, Date] | null>(null);
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [isOpenConfiguration, onOpenConfiguration, onCloseConfiguration] = useIsOpen(false);
  const [startDate, endDate] =
    typeof dateRange === 'string' ? getDateRangeFromType(dateRange!) : dateRange || [undefined, undefined];
  const [isOpenCustomizeBalance, setIsOpenCustomizeBalance] = useState(false);
  const onChangeIsOpenCustomizeBalance = useCallback((value: boolean) => setIsOpenCustomizeBalance(value), []);

  const setDefaultRule = (billableLeavesRules: BillableLeaveRuleDataFragment[]) => {
    const leaveRule = [...billableLeavesRules].sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )[0];
    setSelectedRuleId(leaveRule.id);
  };

  const {
    data: { billableLeavesRules = [] } = {},
    previousData: { billableLeavesRules: previousBillableLeavesRules = [] } = {},
    loading: loadingRules,
  } = useBillableLeavesRulesQuery({
    onCompleted({ billableLeavesRules }) {
      if (billableLeavesRules.length && !selectedRuleId) {
        setDefaultRule(billableLeavesRules);
      }
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId,
    },
    skip: !isOpen,
  });

  useEffect(() => {
    if (!loadingRules && !defaultLeaveRule && !billableLeavesRules.find(({ id }) => id === selectedRuleId)) {
      setSelectedRuleId((prev) => (isOpen ? billableLeavesRules[0]?.id || '' : prev));
    }
  }, [billableLeavesRules]);

  useEffect(() => {
    if (isOpen && defaultLeaveRule) {
      setSelectedRuleId(defaultLeaveRule);
      setDateRange(DateRangeTypes.thisYear);
    }
    if (isOpen && billableLeavesRules.length && !defaultLeaveRule) {
      setDateRange(null);
      setDefaultRule(billableLeavesRules);
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedRuleMonth(billableLeavesRules.find(({ id }) => id === selectedRuleId)?.accrualDate || '');
  }, [selectedRuleId, loadingRules]);

  const queryInterval = useMemo(() => {
    if (startDate && endDate) {
      return {
        end: format(new Date(endDate), DEFAULT_DATE_FORMAT),
        start: format(new Date(startDate), DEFAULT_DATE_FORMAT),
      };
    }

    const [start = '', end = ''] = getDateRangeFromType(DateRangeTypes.thisYear);
    return { start, end };
  }, [startDate, endDate]);

  const {
    data: { billableLeaveRuleBalance } = {},
    previousData: { billableLeaveRuleBalance: previousBillableLeaveRuleBalance } = {},
    loading: loadingBalance,
    refetch,
  } = useBillableLeaveRuleBalanceQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      data: {
        interval: queryInterval,
        billableLeaveRuleId: selectedRuleId,
        projectId,
      },
    },
    skip: !selectedRuleId || !isOpen,
    fetchPolicy: 'network-only',
  });
  const members = useMemo(() => {
    const leaveRuleBalance = (!isOpen ? previousBillableLeaveRuleBalance : billableLeaveRuleBalance) || [];

    // here we fetch only one leave rule
    return leaveRuleBalance[0]?.members?.map((item) => ({ ...item, id: item.memberId })) || [];
  }, [billableLeaveRuleBalance]);

  const selectedMemberData = useMemo(() => members.find(({ memberId }) => selectedMemberId === memberId), [
    selectedMemberId,
  ]);

  const columns = useMemo<ExpandedColumn<BalanceMemberDataFragment>[]>(
    () => [
      {
        id: MEMBER_NAME,
        Header: t('viewProjectDetail.leavesBalance.member')!,
        accessor: ({ last_name, first_name, color }) => ({ last_name, first_name, color }),
        Cell: function member({ value: { last_name, first_name, color } }: CellProps<BalanceMemberDataFragment>) {
          return (
            <UserInfo
              title={`${first_name} ${last_name}`}
              titleClassName={styles.userTitle}
              avatarTitle={getAcronym(first_name, last_name)}
              color={color}
            />
          );
        },
      },
      {
        id: DATE,
        Header: t('viewProjectDetail.leavesBalance.accrualMonth')!,
        accessor: ({ memberId, accrualMonth }) => ({ memberId, accrualMonth }),
        Cell: function accrualMonth({ value: { memberId, accrualMonth } }: CellProps<BalanceMemberDataFragment>) {
          return (
            <div className={styles.accrualMonth}>
              {accrualMonth ? (
                <AccrualMonthCell
                  billableLeaveRuleId={selectedRuleId}
                  memberId={memberId}
                  accrualMonth={accrualMonth}
                  refetchLeaveBalance={refetch}
                />
              ) : (
                <span>{selectedRuleMonth}</span>
              )}
            </div>
          );
        },
      },
      {
        id: LIMIT,
        Header: <span className={styles.balanceHeader}>{t('viewProjectDetail.leavesBalance.balance')}</span>,
        Cell: function balance({ row: { original } }: CellProps<BalanceMemberDataFragment>) {
          return (
            <CustomizeBalanceCell
              balanceData={original}
              ruleId={selectedRuleId}
              refetch={refetch}
              onChangeIsOpenCustomizeBalance={onChangeIsOpenCustomizeBalance}
            />
          );
        },
      },
      {
        Header: ' ',
        id: ACTIONS,
        Cell: function action({ row: { original }, isHovered }: TableCell<BalanceMemberDataFragment>) {
          return (
            <TableActionCell isHovered={isHovered}>
              <Tooltip title={t('viewProjectDetail.leavesBalance.viewHistory')!} alwaysShowTooltip>
                <IconButton
                  size="small"
                  className="editIconButton"
                  onClick={(e) => {
                    setSelectedMemberId(original.memberId);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <ActivityLogIcon className={styles.activityLogIcon} />
                </IconButton>
              </Tooltip>
            </TableActionCell>
          );
        },
      },
    ],
    [selectedRuleId, selectedRuleMonth],
  );

  return (
    <>
      <DialogWrapper
        open={isOpen}
        onClose={onClose}
        className={clsx(
          styles.dialog,
          styles.shown,
          isOpenConfiguration && styles.hidden,
          isOpenCustomizeBalance && styles.hidden,
        )}
        contentClassName={styles.dialogContent}
        title={
          <div className={styles.dialogTitle}>
            {t('viewProjectDetail.leavesBalance.balanceTracking')}

            <Button
              variant="outlined"
              color="secondary"
              disabled={loadingRules}
              onClick={onOpenConfiguration}
              className={styles.settingButton}
              startIcon={<SettingsIcon className={styles.settingIcon} />}
            >
              {t('viewProjectDetail.leavesBalance.leavesConfiguration')}
            </Button>
          </div>
        }
        hideActions
      >
        <div className={styles.box}>
          <LeavesBalanceFilter
            selectedRuleId={selectedRuleId}
            setSelectedRuleId={setSelectedRuleId}
            dateRange={dateRange}
            setDateRange={setDateRange}
            billableLeavesRules={isOpen ? billableLeavesRules : previousBillableLeavesRules}
          />

          <Table
            data={members}
            columns={columns}
            loading={loadingBalance || loadingRules}
            className={styles.tableBox}
          />
        </div>
      </DialogWrapper>

      <LeavesConfiguration
        isOpen={isOpenConfiguration}
        onClose={onCloseConfiguration}
        projectId={projectId}
        leavesRules={billableLeavesRules}
      />

      <MemberLeavesBalance
        isOpen={!!selectedMemberId}
        onClose={() => setSelectedMemberId(null)}
        memberId={selectedMemberId}
        selectedRuleId={selectedRuleId}
        interval={queryInterval}
        selectedMemberData={selectedMemberData}
        filtersComponent={
          <LeavesBalanceFilter
            billableLeavesRules={billableLeavesRules}
            selectedRuleId={selectedRuleId}
            setSelectedRuleId={setSelectedRuleId}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        }
      />
    </>
  );
};
