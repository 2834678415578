import React, { FC, useMemo } from 'react';
import { ActivityHistoriesDataFragment, ActivityHistoryAction, AvailableDomains } from 'generated/types';
import { getAccordionCollapsedTitle, getLogIcon, getLogTitle } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { Accordion, CommonItemDisplayFields, ItemDisplayEditingFields, ItemDisplayFields } from 'components';
import { getDateFrom } from 'utils';

import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
  log: ActivityHistoriesDataFragment;
  currencyCode?: string;
  toggleAccordion: () => void;
  isOpenAccordion: boolean;
  isLongAccordionTitle?: boolean;
  className?: string;
  isShowProjectField?: boolean;
  isShowMemberField?: boolean;
}

export const ActivityLog: FC<Props> = ({
  log,
  currencyCode,
  isOpenAccordion = true,
  toggleAccordion,
  className,
  isLongAccordionTitle,
  isShowProjectField,
  isShowMemberField,
}) => {
  const { action, created_at, type, data, changes } = log;
  const { t } = useTranslation();

  const Icon = getLogIcon({ type, action });

  const logMemo = useMemo(() => log, []);

  const isArchived = action === ActivityHistoryAction.Update && !data['archived_at'] && !!changes['archived_at'];
  const isRestored = action === ActivityHistoryAction.Update && !!data['archived_at'] && !changes['archived_at'];
  const accordionTitle = t(`activityHistory.logTitles.${getLogTitle({ type, action, isArchived, isRestored })}`);

  const showProjectFieldException = log.type === AvailableDomains.Compensation;

  return (
    <div className={clsx(styles.container, className)}>
      <Icon className={styles.icon} />
      <Accordion
        header={
          isLongAccordionTitle ? (
            getAccordionCollapsedTitle({ t, log, accordionTitle })
          ) : (
            <div className="flex flex-wrap">
              <span className={styles.title}>{accordionTitle}</span>
              <span className={styles.text}>{getDateFrom(created_at)}</span>
            </div>
          )
        }
        expanded={isOpenAccordion}
        onChange={toggleAccordion}
      >
        <CommonItemDisplayFields
          item={logMemo}
          isShowProjectField={isShowProjectField && !showProjectFieldException}
          isShowMemberField={isShowMemberField}
        />

        {action === ActivityHistoryAction.Update ? (
          <ItemDisplayEditingFields log={logMemo} currencyCode={currencyCode} />
        ) : (
          <ItemDisplayFields data={logMemo.data} currencyCode={currencyCode} />
        )}
      </Accordion>
    </div>
  );
};
